import { forwardRef } from 'react';
import { Dayjs } from 'dayjs';
import { TimePicker as MuiTimePicker, TimePickerProps as MuiTimePickerProps } from '@mui/x-date-pickers/TimePicker';
// To make Dayjs as valid generic
import '@mui/x-date-pickers/AdapterDayjs';

export type TimePickerProps = MuiTimePickerProps<Dayjs> & {
  fullWidth?: boolean;
  error?: boolean;
  required?: boolean;
  helperText?: React.ReactNode;
};
export const TimePicker = forwardRef<HTMLDivElement, TimePickerProps>(function DatePicker(
  { fullWidth, error, helperText, required, ...props }: TimePickerProps,
  ref,
) {
  return (
    <MuiTimePicker
      slotProps={{
        textField: {
          fullWidth,
          required,
          error,
          helperText,
          inputRef: ref,
        },
      }}
      {...props}
    />
  );
});
