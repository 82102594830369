import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import { Box, ClosableDrawer, PrimaryPaper } from '@atoms/layout';
import { useDirectQualificationsStore, useOrgQualificationsStore, useOrgSpecialtiesStore } from '@libs/store/settings';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid, Stack, styled, Typography } from '@mui/material';
import { Button } from '@atoms/buttons';
import { useOrgDepartmentsStore } from '@libs/store/settings/departments';
import { useShiftAddEditStore } from '@libs/store/shifts';
import { Shift } from '@libs/models/shifts';
import { v4 as uuidV4 } from 'uuid';
import { DateHelper } from '@bryntum/schedulerpro';
import { useForm } from 'react-hook-form';
import { Form } from '@molecules/form/form';
import {
  FormBlock,
  FormCheckbox,
  FormDatePicker,
  FormMultiselect,
  FormRadioGroup,
  FormSelect,
  FormTextField,
  FormTimePicker,
} from '@molecules/form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocationStore } from '@libs/store/locations';
import { SingleShift } from '@libs/models/shifts/shift';
import { useNotification } from '@libs/snackbar';
import { useTalentsStore } from '@libs/store/talents';
import { useShiftSeriesAddEditStore } from '@libs/store/shifts/shiftSeriesAddEditStore';
import { ShiftSeriesUpdate } from '@libs/models/shifts/series';

type EditSeriesDrawerProps = {
  onSaveCall?: () => void;
};
export function EditSeriesDrawer({ onSaveCall }: EditSeriesDrawerProps) {
  const intl = useIntl();
  const { showError } = useNotification();

  const {
    drawerOpen,
    loading: seriesLoading,
    data: currentSeriesDetails,
    seriesId,
    closeShiftSeriesDrawer,
    load,
    drawerMode,
    createShift,
  } = useShiftSeriesAddEditStore();
  const { locations, loadLocations } = useLocationStore();
  const locationOptions = locations.map((l) => ({
    key: l.id,
    label: l.name,
    value: l.id,
  }));

  useEffect(() => {
    loadLocations();
  }, [loadLocations]);

  const qualStore = useDirectQualificationsStore();
  const { load: loadDirectQualifications, loading: isDirectListLoading } = qualStore;
  const directQualifications = qualStore.data || [];

  const orgQualStore = useOrgQualificationsStore();
  const { orgQualifications, load: loadOrgQuals, loading: isOrgQualListLoading } = orgQualStore;
  const qualificationsOptions =
    orgQualifications?.direct.map((qual) => ({
      key: qual.id,
      label: `(${qual.abbreviation}) ${qual.name}`,
      value: qual.id,
    })) ?? [];

  const { loadSpecialtiesList: loadSpecialties, orgSpecialties } = useOrgSpecialtiesStore();
  const orgSpecialtiesOptions = orgSpecialties.map((spec) => ({
    key: spec.id,
    label: spec.name,
    value: spec.id,
  }));
  useEffect(() => {
    loadSpecialties();
  }, [loadSpecialties]);

  const { loadTree, departments } = useOrgDepartmentsStore();
  const realDepartments = departments.filter((dep) => dep.type === 'department');
  const departmentsOptions = realDepartments.map((dep) => ({
    key: dep.id,
    label: dep.label,
    value: dep.id,
  }));
  const subDepartments = departments.filter((dep) => dep.type === 'subdepartment');
  const subDepartmentsOptions = subDepartments.map((dep) => ({
    key: dep.id,
    label: dep.label,
    value: dep.id,
  }));
  useEffect(() => {
    loadTree();
  }, [loadTree]);

  const { load: loadProviders, talents } = useTalentsStore();
  const talentsOptions = talents.map((talent) => ({
    key: talent.id,
    label: [talent.firstName, talent.lastName].join(' '),
    value: talent.id,
  }));
  useEffect(() => {
    // TODO: use autocomplete
    loadProviders({ size: 50 });
  }, [loadProviders]);

  const isAddDialog = drawerMode == 'add';
  const localizationSubset = isAddDialog ? 'shifts.addSeriesDrawer' : 'shifts.editSeriesDrawer';
  const open = drawerOpen;

  const loading = seriesLoading || isOrgQualListLoading;

  const initial: ShiftSeriesUpdate = {
    numberOfCopies: 0, // positive int
    assignees: [], // array of ids
    staffingType: 'All',
    startDate: new Date(),
    endDate: new Date(),
    schedule: [],
    departmentNodeId: '',
    specialtyId: '',
    locationId: '',
    timeZone: '',
    staffNurseQualificationFilterIds: [], // Qualification ids
    staffOtherQualificationFilterIds: [], // Qualification ids
    tags: [],
    note: '',
    isUrgent: false,
    subDepartmentNodeId: '',
    isNeedToPublish: false,
  };

  const [currentShift, setCurrentShift] = useState<ShiftSeriesUpdate>({
    ...initial,
    ...(currentSeriesDetails || {}),
  });

  const methods = useForm<ShiftSeriesFormData>({
    defaultValues: {
      template: '',
      numberOfCopies: 0,
      isUrgent: currentShift.isUrgent,

      durationType: 'setEndDate',
      startDate: dayjs(currentShift.startDate),
      endDate: dayjs(currentShift.endDate),

      location: '',
      department: '',
      subDepartment: '',
      speciality: '',

      staffingType: 'All',
      qualifications: [],
      assignees: [],

      tags: [],
      notes: '',
    },
  });
  const { setFocus, setValue, getValues } = methods;

  const [errors, setErrors] = useState<boolean>(false);

  useEffect(() => {
    if (seriesId) {
      load(seriesId);
    }
  }, [seriesId]);

  useEffect(() => {
    if (!orgQualifications) {
      loadOrgQuals();
    }
  }, [orgQualifications]);

  useEffect(() => {
    setCurrentShift({
      ...currentShift,
      ...currentSeriesDetails,
    });
  }, [currentSeriesDetails]);

  useEffect(() => {
    if (!directQualifications.length) {
      loadDirectQualifications();
    }
  }, [loadDirectQualifications]);

  const handleClose = () => {
    closeShiftSeriesDrawer();
    methods.reset();
  };

  useEffect(() => {
    setErrors(false);
  }, [currentShift]);

  const handleSave = async () => {
    setErrors(errors);
    await methods.handleSubmit(handleSubmit)();
    onSaveCall && onSaveCall();
  };
  const handleSubmit = async (data: ShiftSeriesFormData) => {
    // if (!data.startDay || !data.startTime || !data.endTime) {
    //   throw new Error('Please select start and end time');
    // }
    // const startDate = dayjs(startDay)
    //   .set('hour', startTime.get('hour'))
    //   .set('minute', startTime.get('minute'))
    //   .set('second', startTime.get('second'));
    // const endDate = dayjs(startDate)
    //   .set('hour', endTime.get('hour'))
    //   .set('minute', endTime.get('minute'))
    //   .set('second', endTime.get('second'));
    // const durationInSeconds = endDate.diff(startDate, 'seconds');

    try {
      // await createShift({
      //   ...data,
      //   startDate,
      //   durationInSeconds,
      //   timeZone: startDate.format('Z'),
      //   staffOtherQualificationFilterIds: [],
      //   staffNurseQualificationFilterIds: qualifications,
      //   departmentNodeId: department,
      //   specialityId: speciality,
      //   locationId: location,
      //   tags: [tags],
      //   note: notes,
      // });
      // handleClose();
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
  };

  const handlePublish = async () => {};

  const handleCancel = handleClose;

  return (
    <ClosableDrawer
      open={open}
      loading={loading}
      onClose={handleClose}
      controls={
        <Stack direction={'row-reverse'} spacing={2} sx={(theme) => ({ paddingBottom: theme.spacing(1) })}>
          <MenuButton variant="contained" onClick={handlePublish} disabled={loading}>
            <FormattedMessage id={`${localizationSubset}.menuButtons.publish.label`} />
          </MenuButton>
          <MenuButton variant="outlined" onClick={handleSave} disabled={loading}>
            <FormattedMessage id="common.save" />
          </MenuButton>
          <Box flex={1} />
          <MenuButton
            onClick={handleCancel}
            sx={(theme) => ({ color: theme.palette.text.secondary })}
            disabled={loading}
          >
            <FormattedMessage id={`${localizationSubset}.menuButtons.back.label`} />
          </MenuButton>
        </Stack>
      }
    >
      <Stack direction={'column'} sx={{ height: '100%' }} spacing={2} width={{ xs: '300px', sm: '600px' }}>
        {/* Header */}
        <Stack direction={'row'}>
          <Typography variant="h5">
            <FormattedMessage id={`${localizationSubset}.dialogHeading`} />
          </Typography>
          <Box flex={1} />
        </Stack>
        <Form formUtils={methods} style={{ height: '100%' }} onSubmit={handleSubmit}>
          {/* Shift configuration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.shiftTemplate.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.shiftConfiguration.shiftTemplate.placeholder`,
                })}
                fullWidth
                name="template"
                options={[]}
              />
              <FormTextField
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.quantity.label`} />}
                fullWidth
                name="numberOfCopies"
              />
            </Stack>
            <FormCheckbox
              name="isUrgent"
              label={<FormattedMessage id={`${localizationSubset}.shiftConfiguration.markAsUrgent.label`} />}
              size="medium"
            />
          </FormBlock>

          {/* Date and duration */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.dateAndDuration.sectTitle`} />}>
            <Stack direction={'column'} spacing={1}>
              <FormRadioGroup
                name="durationType"
                label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.label`} />}
                items={[
                  {
                    label: (
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.items.setEndDate`} />
                    ),
                    value: 'setEndDate',
                  },
                  {
                    label: (
                      <FormattedMessage id={`${localizationSubset}.dateAndDuration.durationType.items.infiniteLoop`} />
                    ),
                    value: 'infiniteLoop',
                  },
                ]}
                required
                disabled={loading}
                row
              />
              <Stack direction={'row'} spacing={2}>
                <FormDatePicker
                  disabled={loading}
                  label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.startDate.label`} />}
                  fullWidth
                  name="startDate"
                  defaultValue={null}
                  required
                />
                <FormDatePicker
                  disabled={loading}
                  label={<FormattedMessage id={`${localizationSubset}.dateAndDuration.endDate.label`} />}
                  fullWidth
                  name="endDate"
                  defaultValue={null}
                  required
                />
              </Stack>
            </Stack>
          </FormBlock>
          {/* Series details */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.seriesDetails.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.seriesDetails.seriesPreset.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.seriesDetails.seriesPreset.placeholder`,
                })}
                fullWidth
                name="seriesPreset"
                options={[]}
              />
              <FormSelect
                disabled
                label={<FormattedMessage id={`${localizationSubset}.seriesDetails.shiftTypePreset.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.seriesDetails.shiftTypePreset.placeholder`,
                })}
                fullWidth
                name="shiftTypePreset"
                options={[]}
              />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <FormTimePicker
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.seriesDetails.startTime.label`} />}
                fullWidth
                name="startTime"
                defaultValue={null}
                required
              />
              <FormTimePicker
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.seriesDetails.endTime.label`} />}
                fullWidth
                name="endTime"
                defaultValue={null}
                required
              />
            </Stack>
          </FormBlock>
          {/* Location */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.location.sectTitle`} />}>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.locationName.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.locationName.placeholder`,
                })}
                fullWidth
                name="location"
                options={locationOptions}
              />
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.department.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.department.placeholder`,
                })}
                fullWidth
                name="department"
                options={departmentsOptions}
              />
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.subDepartment.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.subDepartment.placeholder`,
                })}
                fullWidth
                name="subDepartment"
                options={subDepartmentsOptions}
              />
              <FormSelect
                disabled={loading}
                label={<FormattedMessage id={`${localizationSubset}.location.specialty.label`} />}
                placeholder={intl.formatMessage({
                  id: `${localizationSubset}.location.specialty.placeholder`,
                })}
                fullWidth
                name="specialty"
                options={orgSpecialtiesOptions}
              />
            </Stack>
          </FormBlock>

          {/* Talent */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.talentConfiguration.sectTitle`} />}>
            <FormRadioGroup
              name="staffingType"
              label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.label`} />}
              items={[
                {
                  label: <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.all`} />,
                  value: 'All',
                },
                {
                  label: (
                    <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.internal`} />
                  ),
                  value: 'Internal',
                },
                {
                  label: (
                    <FormattedMessage id={`${localizationSubset}.talentConfiguration.talentType.items.external`} />
                  ),
                  value: 'External',
                },
              ]}
              required
              disabled={loading}
              row
            />
            {/* TODO: Hidden so far (moved to future release) */}
            {/* <FormRadioGroup
              name="assignType"
              label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.assignType.label`} />}
              items={[
                {
                  label: (
                    <FormattedMessage id={`${localizationSubset}.talentConfiguration.assignType.items.individual`} />
                  ),
                  value: 1,
                },
                {
                  label: <FormattedMessage id={`${localizationSubset}.talentConfiguration.assignType.items.team`} />,
                  value: 2,
                },
              ]}
              required
              disabled={loading}
              row
            /> */}
            <FormMultiselect
              fullWidth
              label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.qualifications.label`} />}
              placeholder={intl.formatMessage({
                id: `${localizationSubset}.talentConfiguration.qualifications.placeholder`,
              })}
              name="qualifications"
              required
              options={qualificationsOptions}
            />
            <FormMultiselect
              fullWidth
              label={<FormattedMessage id={`${localizationSubset}.talentConfiguration.assignees.label`} />}
              placeholder={intl.formatMessage({
                id: `${localizationSubset}.talentConfiguration.assignees.placeholder`,
              })}
              name="assignees"
              options={talentsOptions}
            />
          </FormBlock>

          {/* Additional info */}
          <FormBlock title={<FormattedMessage id={`${localizationSubset}.additionalInfo.sectTitle`} />}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormTextField
                  disabled={loading}
                  label={<FormattedMessage id={`${localizationSubset}.additionalInfo.tag.label`} />}
                  placeholder={intl.formatMessage({
                    id: `${localizationSubset}.additionalInfo.tag.placeholder`,
                  })}
                  fullWidth
                  name="tags"
                />
              </Grid>
            </Grid>
          </FormBlock>
          <PrimaryPaper sx={{ mt: 1 }}>
            <Accordion sx={{ background: 'transparent' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                <FormattedMessage id={`${localizationSubset}.additionalInfo.notes.sectTitle`} />
              </AccordionSummary>
              <AccordionDetails>
                <FormTextField
                  name="notes"
                  fullWidth
                  InputProps={{
                    sx: { height: '150px' },
                  }}
                />
              </AccordionDetails>
            </Accordion>
          </PrimaryPaper>
        </Form>
      </Stack>
    </ClosableDrawer>
  );
}

const MenuButton = styled(Button)(({ theme }) => ({
  minWidth: theme.spacing(16.75),
}));

type ShiftSeriesFormData = {
  template: string;
  numberOfCopies: number;
  isUrgent: boolean;

  durationType: 'setEndDate' | 'infiniteLoop';
  startDate: Dayjs | null;
  endDate: Dayjs | null;

  location: string;
  department: string;
  subDepartment: string;
  speciality: string;

  staffingType: SingleShift['staffingType'];
  qualifications: string[];
  assignees: string[];

  tags: string[];
  notes: string;
};
