import { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import {
  BryntumGrid,
  BryntumSchedulerPro,
  BryntumSplitter,
  BryntumSchedulerProProps,
  BryntumGridProps,
} from '@bryntum/schedulerpro-react';
import {
  SchedulerPro,
  Grid,
  SchedulerResourceModel,
  ProjectModelConfig,
  SchedulerEventModel,
} from '@bryntum/schedulerpro';
import { Shift } from './lib/Shift';
import { Doctor } from './lib/Doctor';
import { Drag } from './lib/Drag';
import { Box, Button, IconButton } from '@mui/material';

import './scheduler.scss';
import { PrimaryPaper } from '@atoms/layout';
import { CalendarItem } from '../components/calendarItem';
import { IntlProvider } from '@libs/intl';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SchedulerStyles } from './styles';

type SchedulerProps = {
  loading: boolean;
  timeZone?: string;
  projectConfig: ProjectModelConfig;
  schedulerConfig: Omit<BryntumSchedulerProProps, 'project'>;
  gridConfig: BryntumGridProps;
  onAssign: (resource: Doctor, shifts: Shift[]) => void;
};

const LS_RIGHT_DRAWER_STATE_KEY = 'ls_crds';

export function Scheduler({ projectConfig, schedulerConfig, gridConfig, onAssign, loading, timeZone }: SchedulerProps) {
  const gridRef = useRef<BryntumGrid>(null);
  const schedulerRef = useRef<BryntumSchedulerPro>(null);
  const dragRef = useRef<Drag>(null);

  const [grid, setGrid] = useState<Grid>();
  const [scheduler, setScheduler] = useState<SchedulerPro>();
  const [isGridCollapsed, setGridCollapsed] = useState(localStorage.getItem(LS_RIGHT_DRAWER_STATE_KEY) === 'true');

  // if (loading) {
  //   schedulerRef.current?.instance.mask('Loading Data...');
  //   gridRef.current?.instance.mask('Loading Data...');
  // } else {
  //   schedulerRef.current?.instance.unmask();
  //   gridRef.current?.instance.unmask();
  // }

  // if(timeZone){
  //   schedulerRef.current?.instance?.timeZone = timeZone;
  // }

  useEffect(() => {
    setScheduler(schedulerRef.current?.instance);
    setGrid(gridRef.current?.instance);
  }, [gridRef, schedulerRef]);

  useEffect(() => {
    // const scheduler = schedulerRef.current?.instance;
    // const grid = gridRef.current?.instance;

    if (!scheduler || !grid) {
      return;
    }
    const { project } = scheduler!;
    if (!project) {
      return;
    }

    // Create a chained version of the event store as our store.
    // It will be filtered to only display events that lack of assignments.
    // Config for grouping requiredRole in ascending mode while webpage loads initially.
    const chainedStore = (grid!.store = project.eventStore.chain(
      (eventRecord: Shift) => !eventRecord.assignments.length,
      undefined,
      {
        groupers: [
          {
            field: 'requiredRole',
            ascending: true,
          },
        ],
      },
    ));

    // When assignments change, update our chained store to reflect the changes.
    project.assignmentStore.on({
      change: () => chainedStore.fillFromMaster(),
      thisObj: grid!,
    });

    (dragRef as Drag).current = new Drag({
      grid: grid!,
      schedule: scheduler!,
      constrain: false,
      outerElement: grid!.element,
      onEventAssign: onAssign,
    });
  }, [grid, scheduler, gridRef, schedulerRef, projectConfig]);

  // We need to destroy Drag instance because React 18 Strict mode
  // runs this component twice in development mode and Drag has no
  // UI so it is not destroyed automatically as grid and scheduler.
  useEffect(() => {
    return () => (dragRef as Drag).current?.destroy?.();
  }, [dragRef]);

  const onSchedulerSelectionChange = useCallback(() => {
    if (!scheduler || !scheduler.features) {
      return;
    }
    const selectedRecords = scheduler!.selectedRecords as SchedulerResourceModel[];
    const { calendarHighlight } = scheduler!.features;
    if (selectedRecords.length > 0) {
      calendarHighlight.highlightResourceCalendars(selectedRecords);
    } else {
      calendarHighlight.unhighlightCalendars();
    }
  }, [scheduler]);

  const onGridSelectionChange = useCallback(() => {
    if (!scheduler || !scheduler.features) {
      return;
    }
    const maxHoursSchedule = 10;
    const selectedRecords = grid!.selectedRecords as Shift[];
    const { calendarHighlight } = scheduler!.features;
    const requiredRoles: Record<string, number> = {};

    selectedRecords.forEach((appointment: Shift) => (requiredRoles[appointment.requiredRole as string] = 1));

    if (Object.keys(requiredRoles).length === 1) {
      const appointment = selectedRecords[0] as Shift;

      const availableTalents = scheduler!.resourceStore.query((talent: Doctor) => {
        const events = scheduler?.assignmentStore.query((evt: any) => talent.id == evt.resourceId);

        // return talent.role === appointment.requiredRole || !appointment.requiredRole;
        const talentQuals = talent.nurseQualification.id;
        const reqQuals = Array.from(
          new Set(
            selectedRecords.reduce((acc, shift) => {
              const shiftQuals = shift.nurseQualifications.map((qual) => qual.id);
              acc.push(...shiftQuals);
              return acc;
            }, [] as string[]),
          ),
        );

        return reqQuals.some((qual) => talentQuals === qual);
      }) as SchedulerResourceModel[];

      calendarHighlight.highlightResourceCalendars(availableTalents);
      // calendarHighlight.highlightResourceCalendarsForEventRecords(selectedRecords);
      // calendarHighlight.highlightEventCalendars(selectedRecords, availableTalents);
    } else {
      calendarHighlight.unhighlightCalendars();
    }
  }, [grid, scheduler]);

  const eventRenderer = useCallback(({ eventRecord }: { eventRecord: SchedulerEventModel }) => {
    return (
      <IntlProvider>
        <CalendarItem data={eventRecord as Shift} mode="scheduler" />
      </IntlProvider>
    );
  }, []);

  const changeRightDrawerState = (state: boolean) => {
    localStorage.setItem(LS_RIGHT_DRAWER_STATE_KEY, state + '');
    setGridCollapsed(state);
  };

  return (
    <PrimaryPaper
      sx={{ height: loading ? '0px' : '100%', display: loading ? 'none' : 'block', minHeight: 0, mt: 2, p: 0 }}
    >
      <SchedulerStyles />
      <div id="content" className={'b-side-by-side'}>
        <div className="scheduler-container">
          <BryntumSchedulerPro
            ref={schedulerRef}
            {...schedulerConfig}
            project={projectConfig}
            onSelectionChange={onSchedulerSelectionChange}
            eventRenderer={eventRenderer}
          />
        </div>
        {!isGridCollapsed && <BryntumSplitter />}
        <div
          className="grid-container"
          style={{ position: 'relative', display: isGridCollapsed ? 'none' : undefined, minWidth: '150px' }}
        >
          <IconButton
            aria-label="delete"
            size="small"
            sx={{
              position: 'absolute',
              zIndex: 1,
              right: 8,
              top: 13,
            }}
            onClick={() => {
              changeRightDrawerState(true);
            }}
          >
            <ArrowForwardIosIcon fontSize="inherit" />
          </IconButton>
          <BryntumGrid
            ref={gridRef}
            cls="b-unplannedgrid"
            {...{
              ...gridConfig,
            }}
            onSelectionChange={onGridSelectionChange}
          />
        </div>
        {isGridCollapsed && (
          <Box sx={{ width: '44px' }}>
            <Button
              startIcon={
                <ArrowForwardIosIcon
                  fontSize="inherit"
                  sx={{
                    transform: `rotate(90deg)`,
                  }}
                />
              }
              sx={(theme) => ({
                width: '150px',
                left: '-52px',
                top: '55px',
                // background: 'red',
                color: theme.palette.text.secondary,
                transform: `rotate(90deg)`,
              })}
              onClick={() => {
                changeRightDrawerState(false);
              }}
            >
              Open Shifts
            </Button>
          </Box>
        )}
      </div>
    </PrimaryPaper>
  );
}
