import { create } from 'zustand';
import { createSingleShift } from '@libs/api/shifts';
import { SingleShift } from '@libs/models/shifts/shift';
import { Shift } from '@libs/models/shifts';
import { ShiftSeries } from '@libs/models/shifts/series';

type State = {
  loading: boolean;
  drawerOpen: boolean;
  seriesId: string;
  drawerMode: 'add' | 'edit';
  initiated: boolean;
  data: ShiftSeries | null;
  load: (id: string) => void;
  openShiftSeriesDrawer: (id?: string) => void;
  closeShiftSeriesDrawer: () => void;
  createShift: (shift: SingleShift) => Promise<void>;
};

export const useShiftSeriesAddEditStore = create<State>()((set, get) => ({
  loading: false,
  drawerOpen: false,
  drawerMode: 'add',
  initiated: false,
  seriesId: '',
  data: null,
  load: async (id) => {
    set({ loading: true });
    try {
      // const resp = await fetchShiftsList(page, perPage, org);
      set({
        loading: false,
        // data: resp.data,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  openShiftSeriesDrawer: (id) => {
    set({ drawerMode: id ? 'edit' : 'add', seriesId: id, drawerOpen: true });
  },
  closeShiftSeriesDrawer: () => {
    set({ drawerOpen: false });
  },
  createShift: async (shift) => {
    await createSingleShift({
      ...shift,
      startDate: shift.startDate.toISOString(),
    });
  },
}));
