import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import { useCurrentAgency } from '@libs/store/agency';
import { useShiftsListStore } from '@libs/store/shifts';
import { Shift } from '@libs/models/shifts';
import { ShiftsIcon } from '@atoms/icons';
import { EmptyListPlaceholder, isDateSeparator, separatorClass } from '@molecules/dataDisplay';
import { DataGrid, DateSeparator } from '@molecules/dataDisplay';
import { useRotationsListColumns } from './shifts/columns';
import { ShiftOld } from '@libs/models/shifts/shift';
import { useRotationsListStore } from '@libs/store/shifts/rotationsList';

export function SeriesNRotationsTable() {
  const { data, load, loading: rotationsListLoading, total, page, perPage, openShiftDetails } = useRotationsListStore();
  const currentAgencyState = useCurrentAgency();
  const agencyId = currentAgencyState.id;

  const loading = rotationsListLoading;

  useMemo(() => {
    load(1, 10, agencyId ?? '');
  }, [load, agencyId]);

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        rows={data}
        loading={loading}
        rowHeight={64}
        columns={useRotationsListColumns()}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        paginationModel={{
          pageSize: perPage,
          page: page,
        }}
        rowCount={total}
        onPaginationModelChange={(params) => {
          load(params.page, params.pageSize, agencyId ?? '');
        }}
        pageSizeOptions={[10, 20]}
        disableRowSelectionOnClick
        getRowHeight={({ model }) => {
          if (model.type === 'separator') {
            return 44;
          }

          return null;
        }}
        getRowClassName={(params) => {
          if (isDateSeparator(params.row)) {
            return separatorClass;
          }
          return '';
        }}
        noRowsOverlay={
          <EmptyListPlaceholder
            icon={<ShiftsIcon active />}
            title={<FormattedMessage id="shifts.table.noRows.title" />}
            subtitle={<FormattedMessage id="shifts.table.noRows.subtitle" />}
          />
        }
        onRowClick={(params) => {
          openShiftDetails(`${params.id}`);
        }}
      />
    </Box>
  );
}

function useRowsGrouped(data: ShiftOld[]): (ShiftOld | DateSeparator)[] {
  return useMemo(() => {
    const groupedData: (ShiftOld | DateSeparator)[] = [];
    data.forEach((shift, index) => {
      const lastItem = data[index - 1];
      const isSameDay = dayjs(shift.shiftStart).isSame(dayjs(lastItem?.shiftStart), 'day');
      if (isSameDay) {
        groupedData.push(shift);
      } else {
        groupedData.push({
          id: shift.shiftStart.getTime().toString(),
          type: 'separator',
          date: shift.shiftStart,
        });
        groupedData.push(shift);
      }
    });

    return groupedData;
  }, [data]);
}
