import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { Fade } from '@mui/material';
import { useLocationStore } from '@libs/store/locations';
import { useShiftsFilter } from '@libs/store/shifts';
import { useTalentsStore } from '@libs/store/talents';
import { ActiveFilters } from '@molecules/dataDisplay';
import { FilterAutocomplete, FilterMultiSelect, SearchField, renderCheckboxOption } from '@atoms/inputs';
import { Box, Flex } from '@atoms/layout';
import { Button } from '@atoms/buttons';
import { FaIcon } from '@atoms/icons';
import { StartDateRangeFilterInput } from './startDateRangeFilterInput';
import { ShiftsFilterMenu } from './filterMenu';
import { useOrgQualificationsStore } from '@libs/store/settings';
import { useEffect } from 'react';

type ShiftsFiltersProps = {
  loading: boolean;
};
export function ShiftsFilters({ loading }: ShiftsFiltersProps) {
  const intl = useIntl();
  const { activeFilters, clearFilter, clearFilters, getFilter, setFilterFromOptions } = useShiftsFilter();
  const { loading: qualListLoading, orgQualifications, load: loadQualifications } = useOrgQualificationsStore();

  const { locations } = useLocationStore();
  const { talents } = useTalentsStore();
  const professionalsOptions = talents.map((talent) => ({
    label: [talent.firstName, talent.lastName].filter(Boolean).join(' '),
    value: talent.id,
    qualifications: talent.nurseQualification.name,
  }));
  const locationsOptions = locations.map((location) => ({
    label: location.name,
    value: location.id,
  }));

  useEffect(() => {
    if (!qualListLoading) {
      loadQualifications();
    }
  }, [ loadQualifications]);

  const qualificationsFilterOptions =
  orgQualifications?.direct.map((qual) => ({
    label: `${qual.abbreviation ? qual.abbreviation : qual.name}`,
    value: qual.id,
  })) || [];
  return (
    <>
      <Flex justifyContent="space-between" gap={2}>
        <Flex gap={2} flexWrap="wrap">
          <Box width="220px">
            <StartDateRangeFilterInput />
          </Box>
          <Box width={220}>
          <FilterMultiSelect
            size="small"
            options={qualificationsFilterOptions}
            inlineLabel
            label={`${intl.formatMessage({ id: 'common.qualification' })}:`}
            onApply={(v) => setFilterFromOptions('qualification', v, qualificationsFilterOptions)}
            onClear={() => clearFilter('qualification')}
            value={getFilter<string[]>('qualification')?.value ?? []}
            // fullWidth
            renderOption={renderCheckboxOption}
          />
          </Box>
          <Box width={220}>
            <FilterAutocomplete
              options={professionalsOptions}
              label={intl.formatMessage({ id: 'fields.talents' })}
              fullWidth
              onClear={() => clearFilter('talents')}
              onApply={(v) => {
                setFilterFromOptions('talents', v, professionalsOptions);
              }}
              value={getFilter<string[]>('talents')?.value ?? []}
              renderLabel={(option) => (
                <Flex justifyContent="space-between" width="100%">
                  <Typography noWrap>{option.label}</Typography>
                  <Typography variant="caption" color="text.secondary" noWrap>
                    {professionalsOptions.find((o) => o.value === option.value)?.qualifications}
                  </Typography>
                </Flex>
              )}
            />
          </Box>
          <Box width={220}>
            <FilterMultiSelect
              size="small"
              options={statusesOptions}
              inlineLabel
              label={`${intl.formatMessage({ id: 'fields.statuses' })}:`}
              fullWidth
              renderOption={renderCheckboxOption}
              onApply={(v) => setFilterFromOptions('status', v, statusesOptions)}
              onClear={() => clearFilter('status')}
              value={getFilter<string[]>('status')?.value ?? []}
            />
          </Box>
        </Flex>
        <SearchField sx={{ width: 260, flexShrink: 0 }} />
      </Flex>
      <Flex mt={1} gap={1} flexWrap="wrap" alignCenter>
        <ShiftsFilterMenu />
        <ActiveFilters
          filters={activeFilters.map((f) => ({
            ...f,
            label: <FormattedMessage id={`shifts.fields.${f.key}`} />,
          }))}
          onDelete={(item) => clearFilter(item.key)}
        />
        <Fade in={activeFilters.length > 0} timeout={150}>
          <Button
            variant="passive"
            onClick={() => clearFilters()}
            startIcon={<FaIcon name="xmark" />}
            sx={{ ml: 'auto' }}
          >
            <FormattedMessage id="common.clearAllFilters" />
          </Button>
        </Fade>
      </Flex>
    </>
  );
}

const statusesOptions = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Assigned', value: 'Assigned' },
  { label: 'Re-assigned', value: 'Re-assigned' },
  { label: 'In progress', value: 'In progress' },
  { label: 'Canceled', value: 'Canceled' },
  { label: 'N/A', value: 'N/A' },
];
