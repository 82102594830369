import Typography from '@mui/material/Typography';
import { Box, Flex } from '@atoms/layout';

export type EmptyListPlaceholderProps = {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
};
export function EmptyListPlaceholder({ icon, title, subtitle }: EmptyListPlaceholderProps) {
  return (
    <Flex alignCenter justifyCenter height="100%" column>
      {icon && (
        <Box fontSize={80} mb={1.5}>
          {icon}
        </Box>
      )}
      {title && (
        <Typography fontSize="28px" fontWeight="500">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Box mt={0.75}>
          <Typography variant="subtitle1" fontWeight={400} color="text.secondary">
            {subtitle}
          </Typography>
        </Box>
      )}
    </Flex>
  );
}
