import { PropsWithChildren } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import MuiToggleButton, {
  toggleButtonClasses,
  ToggleButtonProps as OriginalToggleButtonProps,
} from '@mui/material/ToggleButton';

export interface ToggleButtonProps extends OriginalToggleButtonProps {}

export function ToggleButton({ children, sx, size, ...props }: PropsWithChildren<ToggleButtonProps>) {
  const theme = useTheme();

  return (
    <MuiToggleButton
      size={size}
      sx={{
        // Need to override the default styles with higher specificity
        borderRadius: '99px !important',
        // Need to override the default styles with higher specificity
        border: 'none !important',
        [`&.${toggleButtonClasses.selected}`]: {
          bgcolor: alpha(theme.palette.primary.main, 0.08),
          color: theme.palette.primary.main,
          [`&:hover`]: {
            bgcolor: alpha(theme.palette.primary.main, 0.12),
          },
        },
        [`&.${toggleButtonClasses.sizeMedium}`]: {
          height: '40px',
        },
        textTransform: 'none',
        px: 2,
        fontWeight: props.disabled ? 400 : 500,
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiToggleButton>
  );
}
