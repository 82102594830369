import { ShiftList } from '@libs/models/shifts/shift';
import { request } from '../request';

export async function assignShift(shiftId: string, assigneeId: string) {
  const response = await request.post(`/v2/shifts/assign/${shiftId}`, {
    staffId: assigneeId,
  });
  return response;
}

export async function unassignShift(shiftId: string, reason: string, reasonComment: string) {
  const response = await request.post(`/v2/shifts/unassign/${shiftId}`, {
    reason,
    reasonComment,
  });
  return response;
}

export async function getAssignments(startDate: string, endDate: string) {
  const response = await request.get<{ staffId: string; shiftId: string }[]>('/v2/calendar/assignments', {
    params: {
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
    },
  });
  return response;
}

export async function getEvents(startDate: string, endDate: string) {
  const dataMock: ShiftList[] = Object.values(EventsMocked);

  const response = await request.get<ShiftList[]>('/v3/calendar/events', {
    params: {
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
    },
  });
  response.data = response.data.map((evt: any) => ({
    ...evt,
    nurseQualifications: evt.staffNurseQualificationFilters,
    otherQualifications: evt.staffOtherQualificationFilters,
  }));
  return response;
  // const fake = async () =>
  //   new Promise<{ data: ShiftList[]; total: number }>((resolve) => {
  //     setTimeout(() => {
  //       resolve({ data: dataMock, total: dataMock.length });
  //     }, 1000);
  //   });

  // return await fake();
}

const EventsMocked: ShiftList[] = [
  {
    id: '1',
    startDate: '2024-12-10T03:00:00.000Z',
    endDate: '2024-12-10T04:00:00.000Z',
    nurseQualifications: [
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Pending',
    timeZone: '',
    isUrgent: true,
  },
  {
    id: '2',
    startDate: '2024-12-10T04:00:00.000Z',
    endDate: '2024-12-10T06:00:00.000Z',
    nurseQualifications: [
      {
        id: 'qualification-01jbhf5sdp05mqajjdrzhczt6r',
        abbreviation: 'CNA',
      },
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
  },
  {
    id: '3',
    startDate: '2024-12-10T05:00:00.000Z',
    endDate: '2024-12-10T08:00:00.000Z',
    nurseQualifications: [
      {
        id: 'lpn',
        abbreviation: 'LPN',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
  },
  {
    id: '4',
    startDate: '2024-12-10T08:00:00.000Z',
    endDate: '2024-12-10T012:00:00.000Z',
    nurseQualifications: [
      {
        id: 'qualification-01jbhf5sdp05mqajjdrzhczt6r',
        abbreviation: 'CNA',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
  },
  {
    id: '5',
    startDate: '2024-12-10T09:00:00.000Z',
    endDate: '2024-12-10T013:00:00.000Z',
    nurseQualifications: [
      {
        id: 'qualification-01jbhf5sdp05mqajjdrzhczt6r',
        abbreviation: 'CNA',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
  },
  {
    id: '6',
    startDate: '2024-12-10T14:00:00.000Z',
    endDate: '2024-12-10T018:00:00.000Z',
    nurseQualifications: [
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
  },
  {
    id: '7',
    startDate: '2024-12-11T20:00:00.000Z',
    endDate: '2024-12-11T23:00:00.000Z',
    nurseQualifications: [
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
  },
  {
    id: '8',
    startDate: '2024-12-11T22:00:00.000Z',
    endDate: '2024-13-11T04:00:00.000Z',
    nurseQualifications: [
      {
        id: 'caregiver',
        abbreviation: 'Caregiver',
      },
    ],
    otherQualifications: [],
    staff: {
      id: '',
    },
    status: 'Opened',
    timeZone: '',
    isUrgent: true,
  },
];
