import { QualificationSettings, Specialty } from './organization';

export const SUB_DEPARTMENT_TYPES = {
  subDepartment: 'subdepartment',
  team: 'team',
  unit: 'unit',
} as const;

type subDeptTypeKey = keyof typeof SUB_DEPARTMENT_TYPES;
export type SubDepartmentType = (typeof DEPARTMENT_TYPES)[subDeptTypeKey];

export const DEPARTMENT_TYPES = {
  department: 'department',
  ...SUB_DEPARTMENT_TYPES,
} as const;

type deptTypeKey = keyof typeof DEPARTMENT_TYPES;
export type DepartmentType = (typeof DEPARTMENT_TYPES)[deptTypeKey];

export type OrgDepartmentType = DepartmentType;

export type DepartmentTreeEntity = {
  parentId: string | null;
  locationId?: string | null;
  type: OrgDepartmentType;
  id: string;
  idPath?: string;
  label: string;
  children?: DepartmentTreeEntity[];
  removable: boolean;
};

export type DepartmentDetails = DepartmentTreeEntity & {
  qualifications: QualificationSettings;
  specialties: Specialty[];
};
