import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { OptionProps } from './types';
import { Checkbox } from '../checkbox';

export function renderCheckboxOption({ option, active }: OptionProps) {
  return (
    <MenuItem key={option.value} value={option.value} sx={{ p: 0 }}>
      <Checkbox
        checked={active}
        formControlLabelProps={{
          sx: { mr: 0 },
        }}
      />
      <ListItemText primary={option.label} />
    </MenuItem>
  );
}
