import { useEffect } from 'react';
import { create } from 'zustand';
import { LineOfBusiness } from '@libs/models/settings/linesOfBusiness';

type InitialState = {
  loading: boolean;
  initiated: boolean;
  data: LineOfBusiness[];
  load: () => void;
};

export const useLinesOfBusinessStore = create<InitialState>()((set) => ({
  loading: false,
  initiated: false,
  data: [],
  load: async () => {
    set({ loading: true });
    try {
      // const { data } = await fetchLinesOfBusiness();
      // set({ loading: false, data: data.data });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
}));

export function useLinesOfBusinessData() {
  const { load, loading, data, initiated } = useLinesOfBusinessStore();

  useEffect(() => {
    if (!initiated && !loading) {
      load();
    }
  }, [load, initiated, loading]);

  return { loading, data, initiated };
}

export function useLinesOfBusiness() {
  const { data } = useLinesOfBusinessStore();
  return data;
}
