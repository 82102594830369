import { FormattedMessage, useIntl } from 'react-intl';
import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem } from '@molecules/form/filterMenu';

export function useShiftTypeFilterMenu(): FilterItem {
  const intl = useIntl();
  const { getFilter } = useShiftsFilter();
  const filter = getFilter<string[]>('shiftType');

  return {
    label: <FormattedMessage id="shifts.fields.shiftType" />,
    key: 'shiftType',
    multiple: true,
    valueToLabel: (value) => value.map((v) => intl.formatMessage({ id: `shifts.shiftType.${v}` })).join(', '),
    children: [
      {
        label: <FormattedMessage id="shifts.shiftType.day8" />,
        key: 'day8',
      },
      {
        label: <FormattedMessage id="shifts.shiftType.eve8" />,
        key: 'eve8',
      },
      {
        label: <FormattedMessage id="shifts.shiftType.noc8" />,
        key: 'noc8',
      },
      {
        label: <FormattedMessage id="shifts.shiftType.day12" />,
        key: 'day12',
      },
      {
        label: <FormattedMessage id="shifts.shiftType.noc12" />,
        key: 'noc12',
      },
    ].map((item) => ({
      ...item,
      selected: filter?.value.includes(item.key) || false,
    })),
  };
}
