export function isDateSeparator<T>(row: T | DateSeparator): row is DateSeparator {
  return (row as DateSeparator).type === 'separator';
}

export type DateSeparator = {
  id: string;
  type: 'separator';
  date: Date;
};

export const separatorClass = 'separator-row';
