import { create } from 'zustand';
import { Shift } from '@libs/models/shifts';
import { ShiftList } from '@libs/models/shifts/shift';
import { getAssignments, getEvents } from '@libs/api/shifts/assigning';

type State = {
  loading: boolean;
  initiated: boolean;
  eventsList: ShiftList[];
  assignmentsInitialLoading: boolean;
  assignmentsLoading: boolean;
  assignmentsList: { staffId: string; shiftId: string }[];
  loadEvents: (startDate: Date, endDate: Date) => Promise<void>;
  loadAssignments: (startDate: Date, endDate: Date) => Promise<void>;
  loadCalendarData: (startDate: Date, endDate: Date) => Promise<void>;
};

export const useCalendarShiftsStore = create<State>()((set, get) => ({
  loading: false,
  initiated: false,
  eventsList: [],
  assignmentsInitialLoading: true,
  assignmentsLoading: false,
  assignmentsList: [],

  loadCalendarData: async (startDate: Date, endDate: Date) => {
    set({ loading: true, initiated: false });
    try {
      const resp = await getEvents(startDate.toISOString(), endDate.toISOString());
      set({
        loading: false,
        eventsList: resp.data,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },

  loadEvents: async (startDate: Date, endDate: Date) => {
    set({ loading: true });
    try {
      const resp = await getEvents(startDate.toISOString(), endDate.toISOString());
      set({
        loading: false,
        eventsList: resp.data,
      });
    } finally {
      set({ loading: false });
    }
  },

  loadAssignments: async (startDate: Date, endDate: Date) => {
    set({ assignmentsLoading: true });
    try {
      const resp = await getAssignments(startDate.toISOString(), endDate.toISOString());
      set({
        assignmentsLoading: false,
        assignmentsList: resp.data,
      });
    } finally {
      set({ assignmentsLoading: false, assignmentsInitialLoading: false });
    }
  },
}));
