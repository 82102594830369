import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRulesToDefaultMessage } from '@libs/forms';
import { TimePicker, TimePickerProps } from '@atoms/inputs';

type FormTimePickerProps<T extends FieldValues> = Omit<TimePickerProps, 'name'> & {
  name: Path<T>;
  required?: boolean;
  requiredMessage?: string;
  options?: RegisterOptions<T, Path<T>>;
};
/**
 * Wrapper around `TimePicker` that integrates with react-hook-form.
 */
export function FormTimePicker<T extends FieldValues>({
  name,
  required,
  requiredMessage,
  options,
  defaultValue,
  ...props
}: FormTimePickerProps<T>) {
  const intl = useIntl();
  const { control } = useFormContext<T>();
  const parsedOptions = useRulesToDefaultMessage(options);
  const rules = {
    ...parsedOptions,
    ...(required
      ? {
          required: requiredMessage ?? intl.formatMessage({ id: 'forms.fieldIsRequired' }),
        }
      : {}),
  };

  return (
    <Controller<T>
      name={name}
      control={control}
      rules={rules}
      // @ts-expect-error - `DayJs` is used instead of `PathValue`
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TimePicker {...props} {...field} required={required} error={!!error} helperText={error?.message} />
      )}
    />
  );
}
