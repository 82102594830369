import { QualificationPayRates } from '@libs/models/settings';
import { TalentEditData } from '@libs/models/talents';
import { request } from '../request';

export function editTalent(
  id: string,
  data: Omit<TalentEditData, 'qualificationPayRates'> & { qualificationPayRate: QualificationPayRates },
) {
  const form = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'image') {
      if (!value) {
        form.append('isImageUrlNeedToBeUpdated', 'false');
      } else {
        form.append('isImageUrlNeedToBeUpdated', 'true');
        form.append(key, value as File);
      }
      return;
    }
    if (key === 'availableForTravelDistance') {
      form.append(key, (value as string) ?? '0');
      return;
    }
    if (typeof value === 'object' && value !== null) {
      form.append(key, JSON.stringify(value));
      return;
    }

    form.append(key, value?.toString() ?? '');
  });

  return request.patch<string>(`/v2/internal/staff/${id}`, form);
}
