import { create } from 'zustand';
import { assignShift, unassignShift } from '@libs/api/shifts';

type InitialState = {
  loadingById: Record<string, boolean>;
  assignShift: (shiftId: string, userId: string) => Promise<void>;
  unassignShift: (shiftId: string, data: { reason: string; reasonComment: string }) => Promise<void>;
};

export const useShiftsAssignment = create<InitialState>()((set) => ({
  loadingById: {},
  assignShift: async (shiftId: string, userId: string) => {
    set((data) => ({ loadingById: { ...data.loadingById, [shiftId]: true } }));
    try {
      await assignShift(shiftId, userId);
    } finally {
      set((data) => ({
        loadingById: { ...data.loadingById, [shiftId]: false },
      }));
    }
  },
  unassignShift: async (shiftId: string, data) => {
    set((data) => ({ loadingById: { ...data.loadingById, [shiftId]: true } }));
    try {
      await unassignShift(shiftId, data.reason, data.reasonComment);
    } finally {
      set((data) => ({
        loadingById: { ...data.loadingById, [shiftId]: false },
      }));
    }
  },
}));
