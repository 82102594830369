import { Scheduler } from './scheduler/Scheduler';
import { gridConfig, projectConfig, schedulerConfig } from './config';
import '@bryntum/schedulerpro/schedulerpro.stockholm.css';
import './scheduler/scheduler.scss';
import { Stack, Typography, Box, useTheme, IconButton } from '@mui/material';
import { FaIcon } from '@atoms/icons';
import { DateHelper } from '@bryntum/schedulerpro';
import { Button, MenuSplitButton } from '@atoms/buttons';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useCallback, useEffect, useState } from 'react';
import { EditShiftDrawer } from './drawers/editShift';
import { useShiftAddEditStore } from '@libs/store/shifts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Shift as SchedulerShiftModel, Shift } from './scheduler/lib/Shift';
import { Doctor } from './scheduler/lib/Doctor';
import { useLocationStore } from '@libs/store/locations';
import { getTimeZones } from '@vvo/tzdb';
import { useCalendarShiftsStore } from '@libs/store/shifts/calendarStore';
import { SkeletonProgress } from '@molecules/feedback';
import { useCalendarTalentsStore } from '@libs/store/shifts/calendarTalents';
import { FilterMultiSelect, renderCheckboxOption } from '@atoms/inputs';
import { useShiftsFilter } from '@libs/store/shifts/shiftsFilter';
import { SHIFT_STATUS_LIST, SHIFT_STATUSES } from '@libs/models/shifts/shift';
import { useOrgQualificationsStore } from '@libs/store/settings';
import { assignShift } from '@libs/api/shifts';
import { ShiftInProgressDrawer } from './drawers/shiftInProgress';
import { EditSeriesDrawer } from './drawers/editSeries';
import { EditRotationDrawer } from './drawers/editRotation';
import { useShiftSeriesAddEditStore } from '@libs/store/shifts/shiftSeriesAddEditStore';
import { useShiftRotationAddEditStore } from '@libs/store/shifts/shiftRotationAddEditStore';
import { useShiftInProgressStore } from '@libs/store/shifts/shiftInProgressStore';

export function ShiftsSchedulerPage() {
  const theme = useTheme();
  const intl = useIntl();
  const { drawerOpen: seriesDrawerOpen, openShiftSeriesDrawer } = useShiftSeriesAddEditStore();
  const { drawerOpen: rotationDrawerOpen, openShiftRotationDrawer } = useShiftRotationAddEditStore();
  const { drawerOpen: inProgressDrawerOpen, openInProgressDrawer } = useShiftInProgressStore();
  const { openShiftDrawer } = useShiftAddEditStore();
  const { current: currentLocation, loading: locationsLoading } = useLocationStore();
  const {
    loadCalendarData,
    initiated: isCalendarInitiallyLoaded,
    eventsList,
    loadEvents,
    loadAssignments,
    assignmentsList,
    assignmentsLoading,
    assignmentsInitialLoading,
  } = useCalendarShiftsStore();

  const { loading: talentsLoading, load: loadTalentsList, talents: talentsList } = useCalendarTalentsStore();
  const { activeFilters, clearFilter, clearFilters, getFilter, setFilterFromOptions } = useShiftsFilter();
  const { loading: qualListLoading, orgQualifications, load: loadQualifications } = useOrgQualificationsStore();

  const loading = !(
    isCalendarInitiallyLoaded &&
    !locationsLoading &&
    !talentsLoading &&
    !assignmentsInitialLoading &&
    !qualListLoading
  );

  const timezones = getTimeZones();
  const currentTimeZone = timezones.find((tz) => tz.currentTimeFormat === currentLocation?.timezone);

  const [dateRange, setDateRange] = useState({
    startDate: DateHelper.startOf(new Date(), 'week', undefined, 1),
    endDate: DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days'),
  });

  const [eventsInternal, setEventsInternal] = useState<Shift[]>([]);

  const assignments = assignmentsList.map((assig, idx) => ({
    id: idx,
    event: assig.shiftId,
    resource: assig.staffId,
  }));

  const talentInternal = talentsList.map((tal) => ({
    id: tal.id,
    avatar: tal.imageUrl,
    requiredRole: 'employee',
    staffingType: tal.staffingType,
    staffingRoles: tal.staffingRoles,
    employmentType: tal.employmentType,
    firstName: tal.firstName,
    lastName: tal.lastName,
    phoneNumber: tal.phoneNumber,
    email: tal.email,
    hourlyPayRate: tal.hourlyPayRate,
    staffingStatus: tal.staffingStatus,
    // otherQualifications: tal.otherQualifications,
    nurseQualification: tal.nurseQualification,
    specialities: tal.specialities,
    lastWorkedTime: tal.lastWorkedTime,
    calendar: 'allday',
  }));

  useEffect(() => {
    loadCalendarData(dateRange.startDate, dateRange.endDate);
    loadTalentsList();
    if (!qualListLoading) {
      loadQualifications();
    }
  }, [loadTalentsList, loadCalendarData, loadQualifications]);

  const refreshLists = () => {
    loadEvents(dateRange.startDate, dateRange.endDate);
    loadAssignments(dateRange.startDate, dateRange.endDate);
  };

  useEffect(() => {
    refreshLists();
  }, [dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    setEventsInternal(
      eventsList
        .filter((evt) => {
          if (!activeFilters || !activeFilters.length) {
            return true;
          }
          const statuses = activeFilters.find((filter) => filter.key === 'status');
          const quals = activeFilters.find((filter) => filter.key === 'qualification');
          const isStatusMatch = statuses ? ((statuses?.value as string[]) || []).includes(evt.status) : true;
          const isQualMatch = quals
            ? evt.nurseQualifications.some((qual) => {
                return ((quals?.value as string[]) || []).includes(qual.id);
              })
            : true;
          return isStatusMatch && isQualMatch;
        })
        .map((evt) => {
          const duration = Math.floor(((+new Date(evt.endDate) - +new Date(evt.startDate)) / 1000 / 60 / 60) * 10) / 10;
          return {
            // ...evt,
            id: evt.id,
            // timeZone: evt.timeZone,
            startDate: new Date(evt.startDate).toISOString(),
            endDate: new Date(evt.endDate).toISOString(),
            status: evt.status,
            nurseQualifications: evt.nurseQualifications,
            otherQualifications: evt.otherQualifications,
            requiredRole: 'employee',
            isUrgent: evt.isUrgent,
            manuallyScheduled: true,
            actualStartDate: evt.startDate,
            actualEndDate: evt.endDate,
            grouping: `${DateHelper.format(new Date(evt.startDate), 'MMM D, YYYY')}`,
            duration: duration > 0 ? duration : 0.1,
          };
        }) as unknown as Shift[],
    );
  }, [activeFilters, eventsList]);

  const handleTodayClick = () => {
    setDateRange({
      startDate: DateHelper.startOf(new Date(), 'week', undefined, 1),
      endDate: DateHelper.add(DateHelper.startOf(new Date(), 'week', undefined, 1), 7, 'days'),
    });
  };
  const handleWeekBackClick = () => {
    setDateRange({
      startDate: DateHelper.add(dateRange.startDate, -7, 'days'),
      endDate: dateRange.startDate,
    });
  };
  const handleWeekForwardClick = () => {
    setDateRange({
      startDate: dateRange.endDate,
      endDate: DateHelper.add(dateRange.endDate, 7, 'days'),
    });
  };

  const handlePublishShift = () => {};

  const handleNewShift = () => {
    openShiftDrawer();
  };

  const handleShiftDetailsOpen = (shift: SchedulerShiftModel) => {
    if (['Opened', 'Pending', 'Reopened', 'Unassigned'].includes(shift.status)) {
      // open edit mode drawer
      shift.id && openShiftDrawer(shift.id + ''); // edit mode
    }

    if (['Assigned', 'InProgress', 'Completed'].includes(shift.status)) {
      // open "in progress" view drawer
      // openInProgressDrawer();
    }
  };

  const handleCreateRotation = () => {
    // openShiftRotationDrawer();
  };
  const handleCreateSeries = () => {
    openShiftSeriesDrawer();
  };

  const assignDebounced = debounce_leading(
    ((resource: Doctor, shifts: Shift[]) => {
      shifts.forEach((shift) => {
        shift.status = SHIFT_STATUSES.ASSIGNED;
        assignShift(shift.id as string, resource.id as string);
      });
      shifts[0].project.commitAsync();
    }) as () => void,
    500,
  ) as (resource: Doctor, shifts: Shift[]) => void;

  const handleAssign = useCallback(
    (resource: Doctor, shifts: Shift[]) => {
      assignDebounced(resource, shifts);
    },
    [useCallback],
  );

  const statusFilterOptions = SHIFT_STATUS_LIST.map((status) => ({
    label: intl.formatMessage({ id: `shifts.status.${status}` }),
    value: status,
  }));

  const qualificationsFilterOptions =
    orgQualifications?.direct.map((qual) => ({
      label: `${qual.abbreviation ? qual.abbreviation : qual.name}`,
      value: qual.id,
    })) || [];

  // const shiftTypeFilterOptions = [{
  //   id: 'day',
  //   label: '8h Day',
  // },
  // {
  //   id: 'noc',
  //   label: '12h Noc',
  // },
  // {
  //   id: 'eve',
  //   label: '4h Eve',
  // }
  // ].map((type) => ({
  //   label: type.label,
  //   value: type.id,
  // })) || [];

  return (
    <>
      <Stack
        direction={'row'}
        spacing={2}
        sx={(theme) => ({
          width: '100%',
          paddingLeft: theme.spacing(1),
        })}
      >
        <Box
          sx={{
            paddingTop: '2px',
          }}
        >
          <FaIcon name="calendar-lines" fontSize={24} />
        </Box>
        <Typography variant="h5">{DateHelper.format(dateRange.startDate, 'MMMM YYYY')}</Typography>
        <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: 'white', color: theme.palette.text.primary }}
          onClick={handleTodayClick}
        >
          <FormattedMessage id="shifts.switchForTodayButton.label" />
        </Button>
        <IconButton aria-label="delete" size="small" onClick={handleWeekBackClick}>
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        <IconButton aria-label="delete" size="small" onClick={handleWeekForwardClick}>
          <ArrowForwardIosIcon fontSize="inherit" />
        </IconButton>
        {/* <Button variant="text" startIcon={<AddIcon />}>
          <FormattedMessage id="shifts.addFilterButton.label" />
        </Button> */}
        <Stack direction={'row'} spacing={2}>
          <FilterMultiSelect
            size="small"
            options={statusFilterOptions}
            inlineLabel
            label={`${intl.formatMessage({ id: 'common.status' })}:`}
            onApply={(v) => setFilterFromOptions('status', v, statusFilterOptions)}
            onClear={() => clearFilter('status')}
            value={getFilter<string[]>('status')?.value ?? []}
            // fullWidth
            renderOption={renderCheckboxOption}
          />
          <FilterMultiSelect
            size="small"
            options={qualificationsFilterOptions}
            inlineLabel
            label={`${intl.formatMessage({ id: 'common.qualification' })}:`}
            onApply={(v) => setFilterFromOptions('qualification', v, qualificationsFilterOptions)}
            onClear={() => clearFilter('qualification')}
            value={getFilter<string[]>('qualification')?.value ?? []}
            // fullWidth
            renderOption={renderCheckboxOption}
          />
          {/* <FilterMultiSelect
            size="small"
            options={shiftTypeFilterOptions}
            inlineLabel
            label={`${intl.formatMessage({ id: 'common.qualification' })}:`}
            onApply={(v) => setFilterFromOptions('shiftType', v, shiftTypeFilterOptions)}
            onClear={() => clearFilter('shiftType')}
            value={getFilter<string[]>('shiftType')?.value ?? []}
            fullWidth
            renderOption={renderCheckboxOption}
          /> */}
        </Stack>
        <Box flex={1} />
        {/* <Button variant="outlined" onClick={handlePublishShift}>
          <FormattedMessage id="shifts.publishShiftButton.label" />
        </Button> */}
        <MenuSplitButton
          onMainClick={handleNewShift}
          label={intl.formatMessage({ id: 'shifts.newShiftButton.label' })}
          menuItems={[
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.singleShift.label' }),
              onAction: handleNewShift,
            },
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.newSeries.label' }),
              onAction: handleCreateSeries,
            },
            {
              label: intl.formatMessage({ id: 'shifts.newShiftButton.menu.newRotation.label' }),
              onAction: handleCreateRotation,
            },
          ]}
        />
      </Stack>
      {loading && (
        <Stack direction={'column'} height={'100%'}>
          <Box flex={1} />
          <SkeletonProgress show={loading} />
          <Box flex={1} />
        </Stack>
      )}
      <Scheduler
        loading={loading}
        onAssign={handleAssign}
        gridConfig={{
          ...gridConfig,
          onCellDblClick: (evt) => {
            handleShiftDetailsOpen(evt.record as SchedulerShiftModel);
          },
        }}
        // timeZone={currentTimeZone?.name}
        projectConfig={{
          ...projectConfig,
          resources: talentInternal,
          events: eventsInternal,
          assignments,
          timeRanges: [
            {
              id: 1, // will highlight the Today
              cls: 'todayColumnHighlight',
              name: DateHelper.format(new Date(), 'ddd DD'),
              startDate: DateHelper.startOf(new Date(), 'day'),
              duration: 1,
              durationUnit: 'day',
              style: 'background: #ecf2fc',
            },
          ],
        }}
        schedulerConfig={{
          ...schedulerConfig,
          startDate: dateRange.startDate,
          timeZone: currentTimeZone?.currentTimeOffsetInMinutes,
          endDate: dateRange.endDate,
          onEventDblClick: (evt) => {
            // evt.resourceRecord <- here - the nurse record could be get
            handleShiftDetailsOpen(evt.eventRecord as SchedulerShiftModel);
          },
        }}
      />
      <EditShiftDrawer
        onSaveCall={() => {
          refreshLists();
        }}
      />
      {inProgressDrawerOpen && <ShiftInProgressDrawer />}
      {seriesDrawerOpen && <EditSeriesDrawer />}
      {rotationDrawerOpen && <EditRotationDrawer />}
    </>
  );
}

function debounce_leading(func: () => void, timeout = 300) {
  let timer: NodeJS.Timeout | undefined;
  return (...args: []) => {
    if (!timer) {
      timer = setTimeout(() => {
        clearTimeout(timer);
        timer = undefined;
      }, timeout);
      func.apply(func, args);
    }
  };
}
