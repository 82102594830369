import React from 'react';
import { Analytics, FeatureFlags } from '@libs/analytics';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { Providers } from './Providers.tsx';
import { App } from './app.tsx';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './styles.css';
import { initTime } from '@libs/intl/time.ts';
import { ScrollbarStyles } from '@atoms/layout';
import { FEATURE_FLAG_DEPLOYMENT_KEY, MUI_LICENSE_KEY } from '@libs/config';
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

initTime();
Analytics.track('App:open');
FeatureFlags.init(FEATURE_FLAG_DEPLOYMENT_KEY)
  .then(() => {
    console.log('Feature flags init');
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <Providers>
          <CssBaseline />
          <ScrollbarStyles />
          <App />
        </Providers>
      </React.StrictMode>,
    );
  })
  .catch(console.error);
