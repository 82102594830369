import { FormattedMessage } from 'react-intl';
import { useLocationStore } from '@libs/store/locations';
import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem } from '@molecules/form/filterMenu';

export function useLocationsFilterMenu(): FilterItem {
  const { locations } = useLocationStore();
  const { getFilter } = useShiftsFilter();
  const filter = getFilter<string[]>('location');

  return {
    label: <FormattedMessage id="common.location" />,
    key: 'location',
    multiple: true,
    valueToLabel: (value) => value.map((v) => locations.find((l) => l.id === v)?.name).join(', '),
    children: locations.map((location) => ({
      label: location.name,
      key: location.id,
      selected: filter?.value.includes(location.id) || false,
    })),
  };
}
