import { GlobalStyles } from '@mui/material';

export function SchedulerStyles() {
  return (
    <GlobalStyles
      styles={(theme) => ({
        '.b-container': {
          borderRadius: theme.spacing(2),
        },
        '.b-react-portal-container': {
          width: '100%',
          height: '100%',
        },
        '.unscheduledNameCell': {
          paddingLeft: 0,
          width: '100%',
        },
        '.b-resourceinfo-cell': {},
        '.b-sch-event': {
          backgroundColor: 'white',
          color: theme.palette.text.primary,
          border: 'none !important',
          margin: 0,
        },
        '.b-grid-header': {
          textTransform: 'none',
        },
        '.b-grid-cell.b-group-title': {
          textTransform: 'none',
        },
        '.b-grid-cell': {
          padding: '0',
        },
        '.b-sch-event:not(.b-milestone) ': {
          width: '100% !important',
          margin: '0 !important',
        },
        '.b-jsx-container': {
          width: '100%',
        },
        '.b-sch-event-content': {
          width: '100%',
          margin: '0 !important',
        },
        '.calendar-item-schedule-group-day': {
          backgroundColor: '#a9cad9',
        },
        '.calendar-item-schedule-group-eve': {
          backgroundColor: '#fdd835',
        },
        '.calendar-item-schedule-group-noc': {
          backgroundColor: '#abb9ff',
        },
        '.calendar-item-holder-badge': {
          width: '6px',
          height: '100%',
        },
        '.b-sch-nonworkingtime': {
          background: 'red !important',
        },
        '.calendar-item-holder-timingText': {
          width: '100px',
        },
      })}
    />
  );
}
