import { useEffect } from 'react';
import { create } from 'zustand';
import { fetchJobQualifications, fetchSpecialities } from '@libs/api/talents';
import { Qualification, Specialty } from '@libs/models/talents';

type InitialState = {
  loading: boolean;
  initiated: boolean;
  nurse: Qualification[];
  other: Qualification[];
  specialties: Specialty[];
  load: () => void;
};

export const useJobQualificationsStore = create<InitialState>()((set, get) => ({
  loading: false,
  initiated: false,
  nurse: [],
  other: [],
  specialties: [],
  load: async (force = false) => {
    const { nurse: data, loading } = get();
    if ((loading || data.length > 0) && !force) {
      return;
    }
    set({ loading: true });
    try {
      const {
        data: { nurses, other },
      } = await fetchJobQualifications();
      const {
        data: { data },
      } = await fetchSpecialities({ page: 1, size: 100 });
      set({ loading: false, nurse: nurses, other, specialties: data });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
}));

export function useJobQualificationsData() {
  const { load, loading, nurse: data, initiated } = useJobQualificationsStore();

  useEffect(() => {
    if (!initiated && !loading) {
      load();
    }
  }, [load, initiated, loading]);

  return { loading, data, initiated };
}

export function useJobQualifications() {
  const { nurse: data } = useJobQualificationsStore();

  return data;
}
