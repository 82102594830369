import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@mui/material/Typography';
import { dateRangeShortcuts } from '@libs/models/shifts';
import { isDateRangeFilter } from '@libs/models/common/filter';
import { useShiftsFilter } from '@libs/store/shifts';
import { DateRangeSelector } from '@atoms/inputs';
import { Flex } from '@atoms/layout';

export function StartDateRangeFilterInput() {
  const intl = useIntl();
  const { activeFilters, setFilter, clearFilter } = useShiftsFilter();
  const startDateFilter = activeFilters.find((f) => f.key === 'startDate');
  const startDate = startDateFilter && isDateRangeFilter(startDateFilter) ? startDateFilter.value : undefined;
  const dateLabel = [startDate?.from?.format('MMM D, YYYY'), startDate?.to?.format('MMM D, YYYY')]
    .filter(Boolean)
    .join(' - ');

  return (
    <DateRangeSelector
      onAccept={(v) => {
        if (!v[0] || !v[1]) {
          clearFilter('startDate');
          return;
        }
        setFilter({
          key: 'startDate',
          value: {
            from: v[0],
            to: v[1],
          },
        });
      }}
      fullWidth
      label={
        <Flex>
          <Typography variant="body1" color="text.secondary" sx={{ flexShrink: 0 }}>
            <FormattedMessage id="shifts.fields.startDate" />
            &nbsp;
          </Typography>
          <Typography variant="body1" color="text.primary" noWrap title={dateLabel}>
            {dateLabel || <FormattedMessage id="shifts.startDateAll" />}
          </Typography>
        </Flex>
      }
      shortcutsItems={dateRangeShortcuts.map(({ labelKey, ...sh }) => ({
        ...sh,
        label: intl.formatMessage({ id: labelKey }),
      }))}
      value={[startDate?.from ?? null, startDate?.to ?? null]}
    />
  );
}
