import axios, { AxiosError } from 'axios';
import { API_BASE_URL } from '@libs/config';

export const request = axios.create({
  baseURL: API_BASE_URL,
});

export const updateToken = (token: string | null) => {
  if (!token) {
    request.defaults.headers['Authorization'] = null;
    return;
  }
  request.defaults.headers['Authorization'] = `Bearer ${token}`;
};

const initialToken = localStorage.getItem('token');
updateToken(initialToken);

/**
 * Catch the unauthorized Request
 */
request.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      // @ts-expect-error `string` is assignable to location
      window.location = '/login';
    }

    return Promise.reject(error);
  },
);
