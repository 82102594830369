import { EventModel } from '@bryntum/schedulerpro';
import { QualificationShort } from '@libs/models/settings/organization';
import { ShiftStatus } from '@libs/models/shifts/shift';

// Custom Shift model, based on EventModel with additional fields and changed defaults
export class Shift extends EventModel {
  // declare patient: string; //
  declare requiredRole: string; //
  declare isShared: boolean;

  declare actualStartDate: string | number | Date;
  declare actualEndDate: string | number | Date;
  declare nurseQualifications: QualificationShort[];
  declare otherQualifications: QualificationShort[];
  declare timeZone: string;
  declare status: ShiftStatus;
  declare isUrgent: boolean;
  declare staff: {
    id: string;
  };

  static fields = [
    'patient',
    'requiredRole',
    // override field defaultValue to hours
    { name: 'durationUnit', defaultValue: 'h' },
  ];

  static defaults = {
    // In this demo, default duration for sessions will be hours (instead of days)
    durationUnit: 'h',
  };
}
