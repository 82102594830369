import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useShiftBillingStore } from '@libs/store/shifts';
import { type ShiftBilling } from '@libs/models/shifts';
import { RadioGroup, TextField } from '@atoms/inputs';
import { Currency, InfoBlocks } from '@atoms/dataDisplay';
import { Box, PrimaryPaper } from '@atoms/layout';
import { useEffect } from 'react';
import { DelayedLinearProgress } from '@molecules/feedback';
import Fade from '@mui/material/Fade';

export function ShiftBilling({ shiftId }: { shiftId: string }) {
  const intl = useIntl();
  const { loadBilling, loading, shiftBilling, updateBilling } = useShiftBillingStore();

  useEffect(() => {
    loadBilling(shiftId);
  }, [loadBilling, shiftId]);

  return (
    <>
      <DelayedLinearProgress show={loading} />
      <Fade in={!!shiftBilling}>
        <Box>
          {shiftBilling && (
            <>
              <PrimaryPaper>
                <TextField
                  label={<FormattedMessage id="billing.bonusRate" />}
                  placeholder={intl.formatMessage({ id: 'billing.bonusRate' })}
                  type="number"
                  value={shiftBilling.bonusRate ?? ''}
                  onChange={(e) => updateBilling({ ...shiftBilling, bonusRate: parseInt(e.target.value, 10) })}
                />
                <RadioGroup
                  items={[
                    {
                      label: <FormattedMessage id="billing.rate.perHour" />,
                      value: 'perHour',
                    },
                    {
                      label: <FormattedMessage id="billing.rate.perShift" />,
                      value: 'perShift',
                    },
                  ]}
                  value={shiftBilling.bonusRateType}
                  row
                  sx={{ ml: 2 }}
                  onChange={(e) => updateBilling({ ...shiftBilling, bonusRateType: e.target.value })}
                />
              </PrimaryPaper>
              <InfoBlocks
                title={<FormattedMessage id="billing.rates" />}
                items={[
                  {
                    key: 'billRate',
                    title: <FormattedMessage id="billing.billRate" />,
                    value: shiftBilling.billRate && <Currency value={shiftBilling.billRate} />,
                  },
                  {
                    key: 'payRate',
                    title: <FormattedMessage id="billing.payRate" />,
                    value: shiftBilling.payRate && <Currency value={shiftBilling.payRate} />,
                  },
                  {
                    key: 'bonusAmount',
                    title: <FormattedMessage id="billing.bonusAmount" />,
                    value: shiftBilling.bonusAmount && <Currency value={shiftBilling.bonusAmount} />,
                  },
                ]}
              />
              <InfoBlocks
                title={<FormattedMessage id="billing.totals" />}
                items={[
                  {
                    key: 'billAmount',
                    title: <FormattedMessage id="billing.billAmount" />,
                    value: shiftBilling.billAmount && <Currency value={shiftBilling.billAmount} />,
                  },
                  {
                    key: 'payAmount',
                    title: <FormattedMessage id="billing.payAmount" />,
                    value: shiftBilling.payAmount && <Currency value={shiftBilling.payAmount} />,
                  },
                  {
                    key: 'grossMargin',
                    title: <FormattedMessage id="billing.grossMargin" />,
                    value: shiftBilling.grossMargin && <Currency value={shiftBilling.grossMargin} />,
                  },
                  {
                    key: 'totalTime',
                    title: <FormattedMessage id="billing.totalTime" />,
                    value: shiftBilling.totalTime && (
                      <FormattedNumber value={shiftBilling.totalTime} unit="hour" unitDisplay="long" style="unit" />
                    ),
                  },
                ]}
              />
            </>
          )}
        </Box>
      </Fade>
    </>
  );
}
