import { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import { PrimaryPaper } from '@atoms/layout';

type FormBlockProps = {
  title?: React.ReactNode;
};
export function FormBlock({ children, title }: PropsWithChildren<FormBlockProps>) {
  return (
    <>
      <SubFormTitle>{title}</SubFormTitle>
      <PrimaryPaper>{children}</PrimaryPaper>
    </>
  );
}
function SubFormTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
      {children}
    </Typography>
  );
}
