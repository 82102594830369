import { PropsWithChildren } from 'react';
import { useTheme } from '@mui/material/styles';
import MuiToggleButtonGroup, {
  ToggleButtonGroupProps as OriginalToggleButtonGroupProps,
} from '@mui/material/ToggleButtonGroup';

export interface ToggleButtonGroupProps extends OriginalToggleButtonGroupProps {}

export function ToggleButtonGroup({ children, sx, ...props }: PropsWithChildren<ToggleButtonGroupProps>) {
  const theme = useTheme();

  return (
    <MuiToggleButtonGroup
      sx={{
        bgcolor: theme.palette.background.paper,
        borderRadius: '99px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiToggleButtonGroup>
  );
}
