import { FormattedMessage } from 'react-intl';
import { InfoBlocks } from '@atoms/dataDisplay';
import { useShiftNotesStore } from '@libs/store/shifts';
import { useEffect } from 'react';
import { DelayedLinearProgress } from '@molecules/feedback';
import Fade from '@mui/material/Fade';
import { Box } from '@atoms/layout';

export function ShiftNotes({ shiftId }: { shiftId: string }) {
  const { notes, additionalNotes, loadNotes, loading } = useShiftNotesStore();

  useEffect(() => {
    loadNotes(shiftId);
  }, [loadNotes, shiftId]);

  return (
    <>
      <DelayedLinearProgress show={loading} />
      <Fade in={notes.length > 0}>
        <Box>
          <InfoBlocks
            title={<FormattedMessage id="shifts.dayOneInstructions" />}
            items={notes.map((note) => ({
              key: note.id,
              title: note.title,
              value: note.body,
            }))}
            columns={1}
          />
        </Box>
      </Fade>
      <Fade in={additionalNotes.length > 0}>
        <Box>
          <InfoBlocks
            title={<FormattedMessage id="shifts.additionalNotes" />}
            items={additionalNotes.map((note) => ({
              key: note.id,
              title: note.title,
              value: note.body,
            }))}
            columns={1}
          />
        </Box>
      </Fade>
    </>
  );
}
