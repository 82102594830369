import { create } from 'zustand';
import { createSingleShift } from '@libs/api/shifts';
import { SingleShift } from '@libs/models/shifts/shift';
import { Shift } from '@libs/models/shifts';

type State = {
  loading: boolean;
  drawerOpen: boolean;
  shiftId: string;
  drawerMode: 'add' | 'edit';
  initiated: boolean;
  data: Shift | null;
  load: (id: string) => void;
  openShiftDrawer: (id?: string) => void;
  closeShiftDrawer: () => void;
  createShift: (shift: SingleShift) => Promise<void>;
};

export const useShiftAddEditStore = create<State>()((set, get) => ({
  loading: false,
  drawerOpen: false,
  drawerMode: 'add',
  initiated: false,
  shiftId: '',
  data: null,
  load: async (id) => {
    set({ loading: true });
    try {
      // const resp = await fetchShiftsList(page, perPage, org);
      set({
        loading: false,
        // data: resp.data,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  openShiftDrawer: (id) => {
    set({ drawerMode: id ? 'edit' : 'add', shiftId: id, drawerOpen: true });
  },
  closeShiftDrawer: () => {
    set({ drawerOpen: false });
  },
  createShift: async (shift) => {
    await createSingleShift({
      ...shift,
      startDate: shift.startDate.toISOString(),
    });
  },
}));
