import { request } from '../request';
import { SingleShift } from '@libs/models/shifts/shift';

export function createSingleShift(data: SingleShiftEditData) {
  return request.post<void>('/v2/shifts/single', data);
}

type SingleShiftEditData = Omit<SingleShift, 'startDate'> & {
  startDate: string;
};
