import {
  DomHelper,
  DragHelper,
  DateHelper,
  ScrollManager,
  SchedulerPro,
  Grid,
  Store,
  DragHelperConfig,
} from '@bryntum/schedulerpro';
import { Shift } from './Shift';
import ReactDOMServer from 'react-dom/server';
import { CalendarItem } from '../../components/calendarItem';
import { Doctor } from './Doctor';
import { theme } from '../../../../../../theme';
import { alpha } from '@mui/material/styles';
import { IntlProvider } from '@libs/intl';

type DragConfig = DragHelperConfig & {
  grid: Grid;
  schedule: SchedulerPro;
  constrain: boolean;
  outerElement: HTMLElement;
  onEventAssign: (resource: Doctor, shifts: Shift[]) => void;
};

// Handles dragging unscheduled appointment from the grid onto the schedule
export class Drag extends DragHelper {
  static defaultConfig = {
    callOnFunctions: true,
    autoSizeClonedTarget: false,
    unifiedProxy: true,

    // Prevent removing proxy on drop, we adopt it for usage in the Schedule
    removeProxyAfterDrop: false,

    // Don't drag the actual row element, clone it
    cloneTarget: true,
    // Only allow drops on the schedule area
    dropTargetSelector: '.b-timeline-subgrid',
    // Only allow drag of row elements inside on the unplanned grid
    targetSelector: '.b-grid-row:not(.b-group-row)',
  };

  public scrollManager!: ScrollManager;
  public grid: Grid;
  public outerElement: HTMLElement;
  public schedule!: SchedulerPro;
  public constrain: boolean;
  private context: any;
  private _onAssignCb: (resource: Doctor, shifts: Shift[]) => void;
  current!: Drag;

  constructor(config: DragConfig) {
    super(config);
    this.grid = config.grid;
    this.schedule = config.schedule;
    this.constrain = config.constrain;
    this.outerElement = config.outerElement;
    this.scrollManager = config.schedule.scrollManager as ScrollManager;
    this._onAssignCb = config.onEventAssign;
  }

  override createProxy = (grabbedElement: HTMLElement): HTMLDivElement => {
    const { context, schedule, grid } = this;
    const appointment = grid.getRecordFromElement(grabbedElement) as Shift;
    const durationInPixels = schedule.timeAxisViewModel.getDistanceForDuration(appointment.durationMS);
    const proxy = document.createElement('div');

    proxy.style.cssText = 'background:rgba(0,0,0,0.1); border: 1px solid rgba(0,0,0,0.2)';
    proxy.style.width = `150px`;
    proxy.style.height = '54px';

    // Fake an event bar
    proxy.classList.add('b-sch-event-wrap', 'b-sch-style-border', 'b-unassigned-class', 'b-sch-horizontal');
    proxy.innerHTML = grabbedElement.innerHTML;
    proxy.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <IntlProvider>
        <CalendarItem data={appointment as Shift} mode="holder" />
      </IntlProvider>,
    );
    let totalDuration = 0;
    (grid.selectedRecords as Shift[]).forEach((appointment) => (totalDuration += appointment.duration));
    context.proxy = proxy;
    context.totalDuration = totalDuration;
    return proxy;
  };

  override onDragStart = ({ context }: { context: any }): void => {
    const { schedule, grid } = this;
    const { selectedRecords, store } = grid as Grid;
    const appointment = grid.getRecordFromElement(context.grabbed);

    // save a reference to the appointments being dragged so we can access them later
    context.appointments = selectedRecords.slice();
    context.relatedElements = selectedRecords
      .sort((r1, r2) => (store as Store).indexOf(r1) - (store as Store).indexOf(r2))
      .map((rec) => rec !== appointment && grid.getRowFor(rec).element)
      .filter((el) => el);
    schedule.enableScrollingCloseToEdges(schedule.timeAxisSubGrid);
    // Prevent tooltips from showing while dragging
    schedule.features.eventTooltip.disabled = true;
  };

  override onDrag = ({ context }: { context: any }): void => {
    const { schedule } = this;
    const { appointments, totalDuration, proxy } = context;
    const requiredRole = appointments[0].requiredRole;
    const newStartDate = schedule.getDateFromCoordinate(context.newX, 'round', false);
    const doctor: Doctor = context.target && schedule.resolveResourceRecord(context.target);
    const calendar = doctor?.calendar;
    if (!calendar) {
      return;
    }

    schedule.highlightTimeSpan({
      // Optional, to support animations
      animationId : 'deliveryWindow',
      // Highlight surrounding area
      surround    : true,
      name        : 'Unavailable time',
      // The time span to visualize
      startDate   : appointments[0].startDate,
      endDate     : DateHelper.add(appointments[0].startDate, 1, 'days')
    });

    const isResourceFitQual = appointments.every((shift: Shift) => {
      return shift.nurseQualifications.some((qual) => {
        return doctor.nurseQualification.id === qual.id;
      });
    });

    const isEventDateFit = appointments.every((shift: Shift) => {
      return DateHelper.format(newStartDate, 'YYYY MM DD') === DateHelper.format(new Date(shift.actualStartDate), 'YYYY MM DD')
    });

    const isHaveTimeSlot = appointments.every((shift: Shift) => {
      return doctor.haveFreeTime(shift.actualStartDate, shift.actualEndDate);
    });

    context.valid = isResourceFitQual && isEventDateFit && isHaveTimeSlot;

    // console.log('drag',appointments[0].id)
    if (context.valid) {
      proxy.style.setProperty('background', `${alpha(theme.palette.success.main, 0.1)}`);
      proxy.style.setProperty('border', `1px solid ${alpha(theme.palette.success.main, 0.2)}`);
    } else {
      proxy.style.setProperty('background', `${alpha(theme.palette.error.main, 0.1)}`);
      proxy.style.setProperty('border', `1px solid ${alpha(theme.palette.error.main, 0.2)}`);
    }

    // Save reference to the doctor so we can use it in onShiftDrop
    context.doctor = doctor;
  };

  // Drop callback after a mouse up, take action and transfer the unplanned appointment to the real EventStore (if it's valid)
  override onDrop = async ({ context }: { context: any }) => {
    const { schedule } = this;
    const { doctor } = context;

    schedule.unhighlightCalendars();
    schedule.unhighlightTimeSpans();
    // If drop was done in a valid location, set the startDate and transfer the task to the Scheduler event store
    if (context.valid) {
      const { appointments, element, relatedElements = [], doctor } = context;
      const coordinate = DomHelper.getTranslateX(element);
      const dropDate = schedule.getDateFromCoordinate(coordinate, 'round', false);
      const eventBarEls = [element, ...relatedElements];

      // console.log('Drop date',dropDate, appointments[0].startDate, appointments[0].endDate);
      this._onAssignCb(doctor, appointments);
      for (let i = 0; i < appointments.length; i++) {
        // We hand over the data + existing element to the Scheduler so it do the scheduling
        // await is used so that we have a reliable end date in the case of multiple event drag
        await schedule.scheduleEvent({
          eventRecord: appointments[i],
          // When dragging multiple appointments, add them back to back + assign to resource
          // startDate: appointments[i].startDate, //i === 0 ? dropDate : appointments[i - 1].endDate,
          startDate: i === 0 ? dropDate : appointments[i - 1].endDate,
          // Assign to the doctor (resource) it was dropped on
          resourceRecord: doctor,
          element: eventBarEls[i],
        });
      }
    }

    schedule.disableScrollingCloseToEdges(schedule.timeAxisSubGrid);
    schedule.features.eventTooltip.disabled = false;
    schedule.refreshRows();
    // schedule.refreshColumn(schedule.columns.get('name'));
    schedule.refreshResource(doctor);
    schedule.refreshWithTransition();
  };
}
