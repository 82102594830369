import { create } from 'zustand';
import dayjs from 'dayjs';
import { getTalents, type GetTalentsParams } from '@libs/api/talents';
import { Talent } from '@libs/models/talents';

type TalentsListState = {
  loading: boolean;
  initiated: boolean;
  showEditForm: boolean;
  page: number;
  total: number;
  perPage: number;
  load: (params?: GetTalentsParams) => Promise<void>;
  talents: Talent[];
};

export const useCalendarTalentsStore = create<TalentsListState>()((set, get) => ({
  loading: false,
  initiated: false,
  showEditForm: false,
  talents: [],
  page: 1,
  perPage: 50,
  total: 0,
  load: async (params) => {
    set({
      loading: true,
    });
    const state = get();
    try {
      const { data } = await getTalents({
        page: state.page,
        size: state.perPage,
        ...params,
      });
      set({
        talents: data.items.map((t) => ({
          ...t,
          lastWorkedTime: dayjs(t.lastWorkedTime),
        })),
        total: data.totalCount,
        initiated: true,
        loading: false,
      });
    } finally {
      set({
        loading: false,
      });
    }
  },
}));
