import { gridFilteredDescendantCountLookupSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton';
import { Flex, RotatingBox } from '@atoms/layout';
import { ButtonProps } from '@atoms/buttons';
import { GridRenderCellParams } from './gridRenderCellParams';

export function GridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(apiRef, gridFilteredDescendantCountLookupSelector);
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (rowNode.type !== 'group') {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Flex alignCenter height="100%">
      {filteredDescendantCount > 0 ? (
        <RotatingBox
          active={(rowNode.type === 'group' && rowNode.childrenExpanded) ?? false}
          activeAngle={-180}
          inactiveAngle={0}
        >
          <IconButton onClick={handleClick} tabIndex={-1} size="small">
            <KeyboardArrowDownIcon />
          </IconButton>
        </RotatingBox>
      ) : (
        <span />
      )}
    </Flex>
  );
}
