import { Controller, FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getErrorMessage } from '@libs/forms';
import { MultiSelect, MultiSelectProps } from '@atoms/inputs';

type FormMultiSelectProps<T extends FieldValues> = Omit<MultiSelectProps, 'name' | 'value'> & {
  name: Path<T>;
  requiredMessage?: boolean;
  options?: RegisterOptions<T, Path<T>>;
};
/**
 * Wrapper around `MultiSelect` that integrates with react-hook-form.
 */
export function FormMultiselect<T extends FieldValues>({ name, requiredMessage, ...props }: FormMultiSelectProps<T>) {
  const { required } = props;
  const intl = useIntl();
  const { control } = useFormContext<T>();
  const rules = {
    ...(required
      ? {
          required: requiredMessage ?? intl.formatMessage({ id: 'forms.fieldIsRequired' }),
        }
      : {}),
  };

  return (
    <Controller<T>
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => {
        return (
          <MultiSelect
            {...props}
            required={required}
            error={!!error}
            helperText={getErrorMessage(error)}
            onChange={(value) => {
              onChange(value);
            }}
            {...field}
            value={field.value ?? []}
          />
        );
      }}
    />
  );
}
