import { FormattedMessage, useIntl } from 'react-intl';
import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem } from '@molecules/form/filterMenu';
import { PickersActionBarProps, StaticDateRangePicker } from '@atoms/inputs';
import { Button } from '@atoms/buttons';
import { Flex } from '@atoms/layout';

// TODO: value from store
export function useDateRangeFilterMenu(): FilterItem {
  const intl = useIntl();
  const { dateRangeShortcuts, setFilter, clearFilter } = useShiftsFilter();

  return {
    label: <FormattedMessage id="shifts.fields.startDate" />,
    key: 'startDate',
    renderChildren: ({ onClose }) => (
      <StaticDateRangePicker
        key="startDate"
        shortcuts={dateRangeShortcuts.map(({ labelKey, ...el }) => ({
          ...el,
          label: intl.formatMessage({ id: labelKey }),
        }))}
        onAccept={([from, to]) => {
          if (!from || !to) {
            clearFilter('startDate');
          } else {
            setFilter({
              key: 'startDate',
              value: {
                from,
                to,
              },
            });
          }
          onClose();
        }}
        onCancel={() => {
          onClose();
          clearFilter('startDate');
        }}
        slots={{
          actionBar: ActionBar,
        }}
      />
    ),
  };
}

function ActionBar({ onCancel, onAccept }: PickersActionBarProps) {
  return (
    <div
      // To place controls in the correct place
      style={{
        gridColumn: '1 / 4',
        gridRow: 3,
      }}
    >
      <Flex justifyContent="flex-end" px={2} py={1} minWidth="320px">
        <Button
          onClick={() => {
            onCancel();
          }}
          variant="passive"
        >
          <FormattedMessage id="common.clear" />
        </Button>
        <Button onClick={onAccept}>
          <FormattedMessage id="common.applyFilters" />
        </Button>
      </Flex>
    </div>
  );
}
