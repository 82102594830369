import { create } from 'zustand';
import {
  createDepartment,
  getDepartment,
  getOrgDepartmentsTree,
  removeDepartment,
  saveOrgDepartmentsTree,
  updateDepartment,
} from '@libs/api/settings';
import { DEPARTMENT_TYPES, DepartmentDetails, DepartmentTreeEntity, SUB_DEPARTMENT_TYPES } from '@libs/models/settings';

const DELIMITER = '_|_';

type InitialState = {
  departmentsTreeLoading: boolean;
  departments: DepartmentTreeEntity[];
  departmentsSelection: string[];
  departmentsIDPathMap: Record<string, string>;

  currentDepartmentID: string | null;
  currentDepartmentLocationId: string | null;
  currentDepartmentLoading: boolean;
  currentDepartmentDetails: DepartmentDetails | null;
  editDepartmentDrawerOpen: boolean;

  currentSubDepartmentId: string | null;
  currentSubDepartmentLoading: boolean;
  currentSubDepartmentDetails: DepartmentDetails | null;
  editSubDepartmentDrawerOpen: boolean;

  currentSubDepartmentLocationId: string | null;
  currentSubDepartmentParentId: string | null;
  currentSubdepartmentParentLoading: boolean;
  currentSubDepartmentParentDetails: DepartmentDetails | null;

  departmentTypes: string[];
  subDepartmentTypes: string[];

  saveTree: (departments: DepartmentTreeEntity[]) => Promise<void>;
  loadTree: () => Promise<void>;
  setDepartmentSelection: (selection: string[]) => void;
  reloadSelection: () => void;
  setDepartments: (departments: DepartmentTreeEntity[]) => void;
  setDepartmentSelected: (id: string) => void;
  setDepartmentUnselected: (id: string) => void;
  loadDepartment: (id: string) => Promise<void>;
  loadSubDepartment: (id: string) => Promise<void>;
  loadSubDepartmentParent: (id: string) => Promise<void>;
  createDepartment: (deptInfo: DepartmentDetails, locationId?: string | null) => Promise<void>;
  updateDepartment: (id: string, deptInfo: DepartmentDetails) => Promise<void>;
  removeDepartment: (id: string) => Promise<void>;
  openEditDepartment: (id: string) => void;
  openAddDepartment: (parentId: string, locationId?: string | null) => void;
  closeDepartmentDrawer: () => void;
  openEditSubDepartment: (id: string, parentId: string) => void;
  openAddSubDepartment: (parentId: string, locationId?: string | null) => void;
};

export const useOrgDepartmentsStore = create<InitialState>()((set, get) => ({
  departmentsTreeLoading: false,
  departments: [],
  departmentsSelection: [],
  departmentsIDPathMap: {},

  currentDepartmentID: null,
  currentDepartmentLocationId: null,
  currentDepartmentLoading: false,
  currentDepartmentDetails: null,
  editDepartmentDrawerOpen: false,

  currentSubDepartmentId: null,
  currentSubDepartmentLoading: false,
  currentSubDepartmentDetails: null,
  editSubDepartmentDrawerOpen: false,

  currentSubDepartmentParentId: null,
  currentSubDepartmentLocationId: null,
  currentSubdepartmentParentLoading: false,
  currentSubDepartmentParentDetails: null,

  departmentTypes: Object.values(DEPARTMENT_TYPES),
  subDepartmentTypes: Object.values(SUB_DEPARTMENT_TYPES),

  saveTree: async (departments) => {
    if (!departments) return;
    try {
      // TODO: use real ID
      await saveOrgDepartmentsTree('e0426c93-4c30-479a-9c42-b0864f461b60', departments);
    } finally {
      set({ departments: departments });
    }
  },

  loadTree: async () => {
    set({ departmentsTreeLoading: true });
    try {
      // TODO: use real ID
      const resp = await getOrgDepartmentsTree();
      const info = recoursiveSetIDPath(resp);
      set({
        departments: info.departments,
        departmentsIDPathMap: info.map,
      });
    } finally {
      set({ departmentsTreeLoading: false });
    }
  },

  async createDepartment(departmentInfo: DepartmentDetails, locationId: string | null = null) {
    set({ departmentsTreeLoading: true });
    try {
      const resp = await createDepartment(departmentInfo, locationId);
    } finally {
      set({ departmentsTreeLoading: false });
    }
  },

  async updateDepartment(id: string, deptInfo: DepartmentDetails) {
    set({ departmentsTreeLoading: true });
    try {
      const resp = await updateDepartment(id, deptInfo);
    } finally {
      set({ departmentsTreeLoading: false });
    }
  },

  reloadSelection: () => {
    set({
      departmentsSelection: [],
    });
  },

  setDepartments: (departments: DepartmentTreeEntity[]) => {
    const info = recoursiveSetIDPath(departments);
    set({
      departments: info.departments,
      departmentsIDPathMap: info.map,
    });
  },

  setDepartmentSelection: (selection: string[]) => {
    set({
      departmentsSelection: selection,
    });
  },

  setDepartmentSelected: (idPath: string) => {
    const { departmentsSelection } = get();
    const split = idPath.split(DELIMITER);
    set({
      departmentsSelection: Array.from(new Set([...departmentsSelection, ...split])),
    });
  },

  setDepartmentUnselected: (id: string) => {
    const { departmentsSelection, departmentsIDPathMap } = get();
    // remove element
    let depts = departmentsSelection.filter((elem) => elem != id);
    // // recalculate all the others
    Object.keys(departmentsIDPathMap).forEach((mapId) => {
      const parts = departmentsIDPathMap[mapId].split(DELIMITER);
      if (parts.includes(id)) {
        depts = depts.filter((elem) => elem != mapId);
      }
    });

    set({
      departmentsSelection: depts,
    });
  },

  removeDepartment: async (id: string) => {
    set({ departmentsTreeLoading: true });
    try {
      // TODO: use real ID
      const resp = await removeDepartment(id);
    } finally {
      set({ departmentsTreeLoading: false });
    }
  },

  loadDepartment: async (departmentId: string) => {
    set({ currentDepartmentLoading: true });
    try {
      const resp = await getDepartment(departmentId);
      set({
        currentDepartmentDetails: resp,
      });
    } finally {
      set({ currentDepartmentLoading: false });
    }
  },
  loadSubDepartment: async (departmentId: string) => {
    set({ currentSubDepartmentLoading: true });
    try {
      const resp = await getDepartment(departmentId);
      set({
        currentSubDepartmentDetails: resp,
      });
    } finally {
      set({ currentSubDepartmentLoading: false });
    }
  },
  loadSubDepartmentParent: async (departmentId: string) => {
    set({ currentSubdepartmentParentLoading: true });
    try {
      const resp = await getDepartment(departmentId);
      set({
        currentSubDepartmentParentDetails: resp,
      });
    } finally {
      set({ currentSubdepartmentParentLoading: false });
    }
  },

  openEditDepartment: (id: string) => {
    //flush state
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
    // set needed
    set({ editDepartmentDrawerOpen: true, currentDepartmentID: id });
  },

  openAddDepartment: (locationId: string | null = null) => {
    //flush state
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
    // set needed
    set({ editDepartmentDrawerOpen: true, currentDepartmentLocationId: locationId });
  },

  openEditSubDepartment: (id: string, parentId: string) => {
    //flush state
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
    // set needed
    set({ editSubDepartmentDrawerOpen: true, currentSubDepartmentId: id, currentSubDepartmentParentId: parentId });
  },

  openAddSubDepartment: (parentId: string, locationId: string | null = null) => {
    //flush state
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
    // set needed
    set({
      editSubDepartmentDrawerOpen: true,
      currentSubDepartmentParentId: parentId,
      currentSubDepartmentLocationId: locationId,
    });
  },

  closeDepartmentDrawer: () => {
    set({
      editDepartmentDrawerOpen: false,
      editSubDepartmentDrawerOpen: false,
      currentDepartmentID: null,
      currentSubDepartmentId: null,
      currentDepartmentDetails: null,
      currentSubDepartmentDetails: null,
      currentSubDepartmentParentId: null,
      currentSubdepartmentParentLoading: false,
      currentSubDepartmentParentDetails: null,
    });
  },
}));

type recoursiveSetIDPathProps = { departments: DepartmentTreeEntity[]; map: Record<string, string> };
export function recoursiveSetIDPath(
  departments: DepartmentTreeEntity[],
  parentIDPath: string = 'root',
): recoursiveSetIDPathProps {
  let map: Record<string, string> = {};
  const depts: DepartmentTreeEntity[] = departments.map((dept: DepartmentTreeEntity) => {
    const path = `${parentIDPath}${DELIMITER}${dept.id}`;
    map[dept.id] = path;
    let deep;
    if (dept.children) {
      deep = recoursiveSetIDPath(dept.children, path);
      map = { ...map, ...deep.map };
    }

    return {
      ...dept,
      idPath: path,
      children: deep && deep.departments,
    };
  });

  return {
    departments: depts,
    map,
  };
}
