import { DepartmentDetails, DepartmentTreeEntity } from '@libs/models/settings';
import { request } from '../../request';

export async function getOrgDepartmentsTree(): Promise<DepartmentTreeEntity[]> {
  const response = await request.get<DepartmentTreeEntity[]>('/v2/departments');
  return response.data;

  // const mock: DepartmentTreeEntity[] = [
  //   {
  //     id: '1',
  //     label: 'Nursing',
  //     parentId: 'root',
  //     removable: false,
  //     children: [
  //       {
  //         id: '1.1',
  //         parentId: '1',
  //         label: 'Emergency Nursing',
  //         removable: false,
  //         children: [
  //           { id: '1.1.1', parentId: '1.1', label: 'Medical-Surgical Nursing', type: 'team', removable: false },
  //           { id: '1.1.2', parentId: '1.1', label: 'Pediatric Nursing', type: 'team', removable: false },
  //           { id: '1.1.3', parentId: '1.1', label: 'Trauma Nursing', type: 'unit', removable: false },
  //           { id: '1.1.4', parentId: '1.1', label: 'Cardiac Nursing', type: 'unit', removable: false },
  //         ],
  //         type: 'subDepartment',
  //       },
  //       {
  //         id: '1.2',
  //         parentId: '1',
  //         label: 'Rapid Response (RRT) Nursing',
  //         type: 'subDepartment',
  //         removable: false,
  //       },
  //       {
  //         id: '1.3',
  //         parentId: '1',
  //         label: 'Emergency Geriatric Nursing',
  //         type: 'subDepartment',
  //         removable: false,
  //       },
  //     ],
  //     type: 'department',
  //   },
  //   { id: '3', parentId: 'root', label: 'Accounting', type: 'department', removable: false },
  // ];

  // const fake = async () =>
  //   new Promise<DepartmentTreeEntity[]>((resolve) => {
  //     setTimeout(() => {
  //       resolve(mock);
  //     }, 1000);
  //   });

  // return await fake();
}

export async function saveOrgDepartmentsTree(id: string, tree: DepartmentTreeEntity[]): Promise<void> {
  // return request.get<ResponseWrapper<QualificationSettings>>('/Agency/GetAgencyById', {
  //   params: {
  //     agencyId: id,
  //   },
  // });
  const fake = async () =>
    new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

  return await fake();
}

export async function removeDepartment(id: string): Promise<void> {
  await request.delete(`/v2/departments/${id}`);
  // const fake = async () =>
  //   new Promise<void>((resolve) => {
  //     setTimeout(() => {
  //       resolve();
  //     }, 1000);
  //   });

  // return await fake();
}

export async function getDepartment(id: string): Promise<DepartmentDetails> {
  const response = await request.get(`/v2/departments/${id}`);
  const deptInfo = response.data;

  return {
    id: deptInfo.id,
    label: deptInfo.label,
    type: deptInfo.type,
    qualifications: {
      direct: deptInfo.nurseQualifications,
      other: deptInfo.otherQualifications,
    },
    specialties: deptInfo.specialities,
  } as DepartmentDetails;

  // const mock: Record<string, DepartmentDetails> = {
  //   '1': {
  //     type: 'department',
  //     id: '1',
  //     label: 'Nursing',
  //     parentId: 'root',
  //     removable: false,
  //     qualifications: {
  //       direct: [
  //         {
  //           id: 'test_qual_1',
  //           category: 'nursing_and_patient_care',
  //           name: 'Certified Nursing Assistant',
  //           abbreviation: 'CNA',
  //         },
  //         {
  //           id: 'test_qual_2',
  //           category: 'nursing_and_patient_care',
  //           name: 'Licensed Practical Nurse',
  //           abbreviation: 'LPN',
  //         },
  //         {
  //           id: 'test_qual_3',
  //           category: 'nursing_and_patient_care',
  //           name: 'Licensed Vocational Nurse',
  //           abbreviation: 'LVN',
  //         },
  //         {
  //           id: 'test_qual_4',
  //           category: 'nursing_and_patient_care',
  //           name: 'Registered Nurse',
  //           abbreviation: 'RN',
  //         },
  //         {
  //           id: 'test_qual_5',
  //           category: 'nursing_and_patient_care',
  //           name: 'Nurse Practitioner',
  //           abbreviation: 'NP',
  //         },
  //       ],
  //       other: [],
  //     },
  //     specialties: [],
  //   },
  //   '1.1': {
  //     id: '1.1',
  //     parentId: '1',
  //     label: 'Emergency Nursing',
  //     type: 'subDepartment',
  //     removable: false,
  //     qualifications: {
  //       direct: [
  //         {
  //           id: 'test_qual_1',
  //           category: 'nursing_and_patient_care',
  //           name: 'Certified Nursing Assistant',
  //           abbreviation: 'CNA',
  //         },
  //       ],
  //       other: [],
  //     },
  //     specialties: [],
  //   },
  //   '1.1.1': {
  //     id: '1.1.1',
  //     parentId: '1.1',
  //     label: 'Medical-Surgical Nursing',
  //     type: 'team',
  //     removable: false,
  //     qualifications: {
  //       direct: [
  //         {
  //           id: 'test_qual_2',
  //           category: 'nursing_and_patient_care',
  //           name: 'Licensed Practical Nurse',
  //           abbreviation: 'LPN',
  //         },
  //       ],
  //       other: [],
  //     },
  //     specialties: [],
  //   },
  //   '1.1.2': {
  //     id: '1.1.2',
  //     parentId: '1.1',
  //     label: 'Pediatric Nursing',
  //     type: 'team',
  //     removable: false,
  //     qualifications: {
  //       direct: [
  //         {
  //           id: 'test_qual_3',
  //           category: 'nursing_and_patient_care',
  //           name: 'Licensed Vocational Nurse',
  //           abbreviation: 'LVN',
  //         },
  //       ],
  //       other: [],
  //     },
  //     specialties: [],
  //   },
  //   '1.1.3': {
  //     id: '1.1.3',
  //     parentId: '1.1',
  //     label: 'Trauma Nursing',
  //     type: 'unit',
  //     removable: false,
  //     qualifications: {
  //       direct: [
  //         {
  //           id: 'test_qual_4',
  //           category: 'nursing_and_patient_care',
  //           name: 'Registered Nurse',
  //           abbreviation: 'RN',
  //         },
  //       ],
  //       other: [],
  //     },
  //     specialties: [],
  //   },
  //   '1.1.4': {
  //     id: '1.1.4',
  //     parentId: '1.1',
  //     label: 'Cardiac Nursing',
  //     type: 'unit',
  //     removable: false,
  //     qualifications: {
  //       direct: [],
  //       other: [],
  //     },
  //     specialties: [],
  //   },

  //   '1.2': {
  //     id: '1.2',
  //     parentId: '1',
  //     label: 'Rapid Response (RRT) Nursing',
  //     type: 'subDepartment',
  //     removable: false,
  //     qualifications: {
  //       direct: [],
  //       other: [],
  //     },
  //     specialties: [],
  //   },
  //   '1.3': {
  //     id: '1.3',
  //     parentId: '1',
  //     label: 'Emergency Geriatric Nursing',
  //     type: 'subDepartment',
  //     removable: false,
  //     qualifications: {
  //       direct: [],
  //       other: [],
  //     },
  //     specialties: [],
  //   },
  //   '3': {
  //     id: '2',
  //     label: 'Accounting',
  //     parentId: 'root',
  //     type: 'department',
  //     removable: false,
  //     qualifications: {
  //       direct: [],
  //       other: [],
  //     },
  //     specialties: [],
  //   },
  // };

  // const fake = async () =>
  //   new Promise<DepartmentDetails>((resolve) => {
  //     setTimeout(() => {
  //       resolve(mock[id]);
  //     }, 1000);
  //   });

  // return await fake();
}

export async function createDepartment(
  deptInfo: DepartmentDetails,
  locationId: string | null = null,
): Promise<DepartmentTreeEntity[]> {
  const response = await request.post<DepartmentTreeEntity[]>('/v2/departments', {
    label: deptInfo.label,
    type: deptInfo.type,
    locationId: locationId,
    parentId: deptInfo.parentId,
    nurseQualificationIds: deptInfo.qualifications.direct.map((qual) => qual.id),
    otherQualificationIds: deptInfo.qualifications.other.map((qual) => qual.id),
    specialityIds: deptInfo.specialties.map((spec) => spec.id),
  });
  return response.data;
}

export async function updateDepartment(id: string, deptInfo: DepartmentDetails): Promise<DepartmentTreeEntity[]> {
  const response = await request.patch<DepartmentTreeEntity[]>(`/v2/departments/${id}`, {
    label: deptInfo.label,
    type: 'department',
    locationId: deptInfo.locationId,
    parent: deptInfo.parentId,
    nurseQualificationIds: deptInfo.qualifications.direct.map((qual) => qual.id),
    otherQualificationIds: deptInfo.qualifications.other.map((qual) => qual.id),
    specialityIds: deptInfo.specialties.map((spec) => spec.id),
  });
  return response.data;
}
