import { ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Checkbox } from '@atoms/inputs';
import { FaIcon } from '@atoms/icons';
import { Flex } from '@atoms/layout';
import { FilterItem } from './filterItem';

type FilterMenuItemProps = {
  item: FilterItem;
  onClick?: () => void;
  hasChildren?: boolean;
  active?: boolean;
  label?: ReactNode;
};
export function FilterMenuItem({ onClick, hasChildren, label, active }: FilterMenuItemProps) {
  return (
    <MenuItem onClick={onClick}>
      <Flex justifyContent="space-between" width="100%" alignCenter gap={2}>
        {!hasChildren && (
          <Flex alignCenter>
            <Checkbox checked={active} />
            <Flex ml={-1}>{label}</Flex>
          </Flex>
        )}
        {hasChildren && (
          <>
            {label}
            <FaIcon name="angle-right" />
          </>
        )}
      </Flex>
    </MenuItem>
  );
}
