import { ComponentProps, ReactNode } from 'react';
import { styled } from '@mui/material';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import { AppLogo, AppLogoText } from '@atoms/appLogo';
import { Flex } from '@atoms/layout';
import { UndecoratedNavLink } from '@molecules/navigation/undecoratedNavLink';
import { SidebarButton } from '@molecules';
import { drawerWidth } from '../../../theme';
import { SidebarMenuButton } from '@molecules/navigation';
import { SidebarSubMenuItem } from '@molecules/navigation/sidebarMenuButton';

type SidebarProps = {
  topItems?: MenuItem[];
  bottomItems?: MenuItem[];
};
export const Sidebar = ({ topItems = [], bottomItems = [] }: SidebarProps) => {
  return (
    <SidebarDrawer variant="permanent" anchor="left">
      <Flex justifyCenter>
        <AppLogo />
      </Flex>

      <Flex mt={2} column justifyContent="space-between" height="100%">
        <List>
          <Stack gap={1}>
            {topItems.map((props, idx) => (
              <SidebarItem {...props} key={idx} />
            ))}
          </Stack>
        </List>

        <Flex column alignCenter>
          <List>
            <Stack gap={1}>
              {bottomItems.map((props, idx) => (
                <SidebarItem {...props} key={idx} />
              ))}
            </Stack>
          </List>

          <AppLogoText />
        </Flex>
      </Flex>
    </SidebarDrawer>
  );
};

export const SidebarItem = ({ id, path, icon,label,children, anchorPosition }: MenuItem) => {
  if (children?.length) {
    return (
      <SidebarMenuButton
        active={location.pathname.includes(`/${path}`)}
        icon={icon}
        label={label}
        children={children}
        anchorPosition={anchorPosition}
      />
    );
  }
  return (
    <UndecoratedNavLink to={`/${path}`} key={id}>
      {({ isActive }) => <SidebarButton active={isActive} icon={icon} label={label} />}
    </UndecoratedNavLink>
  );
};

export type MenuItem = {
  id: string;
  label: ReactNode;
  icon: ComponentProps<typeof SidebarButton>['icon'];
  path?: string;
  children?: SidebarSubMenuItem[];
  // anchor position for the context submenu
  anchorPosition?: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
};



const SidebarDrawer = styled(Drawer)`
  width: ${drawerWidth}px;

  flex-shrink: 0;
  & .${drawerClasses.paper} {
    padding: 12px;
    width: ${drawerWidth}px;
    box-sizing: border-box;
    border-radius: 0 16px 16px 0;
    border: none;
  }
`;
