import { PickersActionBarProps as MuiPickersActionBarProps, PickersShortcutsItem } from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker as MuiStaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { Dayjs } from 'dayjs';

type StaticDateRangePickerProps = {
  shortcuts: PickersShortcutsItem<[Dayjs | null, Dayjs | null]>[];
  onAccept?: (range: [Dayjs | null, Dayjs | null]) => void;
  onCancel?: () => void;
  slots?: {
    actionBar?: React.ElementType<PickersActionBarProps>;
  };
};
export function StaticDateRangePicker({ shortcuts, onAccept, slots, onCancel }: StaticDateRangePickerProps) {
  return (
    <MuiStaticDateRangePicker
      slotProps={{
        shortcuts: {
          items: shortcuts,
        },
        actionBar: {
          actions: ['cancel', 'accept'],
          onCancel: onCancel,
        },
      }}
      slots={slots}
      calendars={2}
      onAccept={onAccept}
    />
  );
}

export type PickersActionBarProps = MuiPickersActionBarProps;
