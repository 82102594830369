import { FormattedMessage, useIntl } from 'react-intl';
import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem } from '@molecules/form/filterMenu';

export function useSharedWithinFilterMenu(): FilterItem {
  const intl = useIntl();
  const { getFilter } = useShiftsFilter();
  const filter = getFilter<string[]>('sharedWithin');

  return {
    label: <FormattedMessage id="filters.sharedWithin.menuLabel" />,
    key: 'sharedWithin',
    transformFilter: (filterItems) => {
      if (filterItems[0] === 'allTime') {
        return [];
      }
      return filterItems;
    },
    valueToLabel: (value) => intl.formatMessage({ id: `filters.sharedWithin.${value[0]}` }),
    children: [
      {
        label: <FormattedMessage id="filters.sharedWithin.last24Hours" />,
        key: 'last24Hours',
      },
      {
        label: <FormattedMessage id="filters.sharedWithin.last48Hours" />,
        key: 'last48Hours',
      },
      {
        label: <FormattedMessage id="filters.sharedWithin.last7Days" />,
        key: 'last7Days',
      },
      {
        label: <FormattedMessage id="filters.sharedWithin.last30Days" />,
        key: 'last30Days',
      },
      {
        label: <FormattedMessage id="filters.sharedWithin.allTime" />,
        key: 'allTime',
      },
    ].map((item) => {
      if (item.key === 'allTime' && !filter) {
        return {
          ...item,
          selected: true,
        };
      }
      return {
        ...item,
        selected: filter?.value.includes(item.key),
      };
    }),
  };
}
