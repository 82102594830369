import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { SxProps, Theme, useTheme } from '@mui/material/styles';

type TooltipNoteProps = TooltipProps & {
  popoverTooltipSx?: SxProps<Theme>;
};
export function TooltipNote({ popoverTooltipSx, ...props }: TooltipNoteProps) {
  const theme = useTheme();

  return (
    <Tooltip
      {...props}
      PopperProps={{
        ...props.PopperProps,
        sx: {
          [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.common.black,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[4],
            borderRadius: '20px',
            padding: 2,
            ...popoverTooltipSx,
          },
          ...props.PopperProps?.sx,
        },
      }}
    />
  );
}
