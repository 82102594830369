import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box, Flex } from '@atoms/layout';
import { Button } from '@atoms/buttons';
import { TextField } from './textField';
import { Checkbox } from './checkbox';

export type FilterAutocompleteProps = {
  fullWidth?: boolean;
  loading?: boolean;
  async?: boolean;
  options: Option[];
  label: ReactNode;
  value: string[];
  onApply?: (value: string[]) => void;
  onClear?: () => void;
  renderLabel?: (option: Option, { selected }: { selected: boolean }) => ReactNode;
  onSearchTermChange?: (value: string) => void;
};
export function FilterAutocomplete({
  label,
  value,
  onApply,
  onClear,
  renderLabel,
  async,
  onSearchTermChange,
  ...props
}: FilterAutocompleteProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [internalValue, setInternalValue] = useState<string[]>(value);
  const optionValues = internalValue
    .map((v) => props.options.find((o) => o.value === v))
    .filter((o): o is Option => !!o);
  // Need to manage input value to prevent clearing on option selection
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  useEffect(() => {
    onSearchTermChange?.(searchTerm);
  }, [searchTerm]);

  return (
    <Autocomplete
      sx={() => ({
        [`& .${autocompleteClasses.inputRoot}`]: {
          flexWrap: 'nowrap',
        },
      })}
      multiple
      // When async is true, the local filtering is disabled by providing a dummy function
      filterOptions={async ? (x) => x : undefined}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);
        setSearchTerm('');
        setInternalValue(value);
      }}
      disableCloseOnSelect
      openOnFocus
      inputValue={searchTerm}
      renderTags={(option) => (
        <Flex width="100%" minWidth={0}>
          <Typography noWrap title={option.map((o) => o.label).join(', ')}>
            {option.map((o) => o.label).join(', ')}
          </Typography>
        </Flex>
      )}
      value={optionValues}
      onChange={(_, newValue, reason) => {
        if (reason === 'selectOption') {
          setInternalValue((value) => [...new Set(value.concat(newValue.map((v) => v.value)))]);
        } else if (reason === 'removeOption') {
          setInternalValue((value) => value.filter((v) => !newValue.map((v) => v.value).includes(v)));
        } else {
          setInternalValue(newValue.map((v) => v.value));
        }
      }}
      slotProps={{
        popper: { style: { width: 'auto' } },
        paper: {
          sx: {
            [`& .${autocompleteClasses.listbox} .${autocompleteClasses.option}`]: {
              height: '38px',
              paddingLeft: 0,
            },
          },
        },
      }}
      ListboxComponent={(props) => (
        <>
          <ul {...props} />
          <Flex
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
            minWidth="340px"
            gap={2}
            p={2}
            pt={1}
            onMouseDown={(event) => {
              // Prevent input blur when interacting with the "controls" content
              event.preventDefault();
            }}
          >
            <Box color="text.secondary">
              <Button
                rounded
                size="small"
                variant="text"
                color="inherit"
                onClick={() => {
                  onClear?.();
                }}
              >
                <FormattedMessage id="common.clear" />
              </Button>
            </Box>
            <Button rounded variant="contained" size="small" onClick={() => onApply?.(internalValue)}>
              <FormattedMessage id="common.applyFilters" />
            </Button>
          </Flex>
        </>
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.value}>
            <Checkbox checked={selected} formControlLabelProps={{ sx: { mr: 0 } }} />
            {renderLabel ? renderLabel(option, { selected }) : option.label}
          </li>
        );
      }}
      renderInput={({ InputProps, ...params }) => {
        return (
          <TextField
            {...params}
            fullWidth={props.fullWidth}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              ...InputProps,
              startAdornment: isFocused ? (
                <></>
              ) : (
                <Flex pl={1} width="100%">
                  <Typography variant="body1" color="text.secondary" sx={{ flexShrink: 0 }}>
                    {label}:&nbsp;
                  </Typography>
                  {InputProps.startAdornment ?? 'All'}
                </Flex>
              ),
              endAdornment: (
                <>
                  {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      {...props}
    />
  );
}

type Option = { value: string; label: string };
