import { ComingSoon } from '@organisms/dataDisplay';
import { ShiftsSchedulerPage } from './calendar';
import { RotationsListPage } from './shiftsList/rotationsListPage';
import { ShiftsListPage } from './shiftsList/shiftsListPage';

const ViewTypes: Record<string, JSX.Element> = {
  "calendarByAssignee": <ShiftsSchedulerPage />,
  "calendarByRole": <ShiftsSchedulerPage />,
  "calendarMonthlyPlanning": <ShiftsSchedulerPage />,
  "listAllShifts": <ShiftsListPage />,
  "listSeriesNRotations": <RotationsListPage />,
  "templatesShiftsSeriesRotations": <ComingSoon />,
  "templatesSeriesNRotations": <ComingSoon />,
  "historyLog": <ComingSoon />,
} as const;

type Props = {
  viewType: keyof typeof ViewTypes;
};

export function Component({ viewType }: Props) {
  return ViewTypes[viewType];
}
