import { useEffect } from 'react';
import { useTalentsStore } from '@libs/store/talents';
import { ShiftDetailsDrawer, ShiftsFilters, ShiftTableMenu } from '@organisms/shifts';
import { ShiftsTable } from '@organisms/dataDisplay';
import { Box, Flex, PrimaryPaper } from '@atoms/layout';
import { useShiftsListStore } from '@libs/store/shifts';

export function ShiftsListPage() {
  const { load } = useTalentsStore();
  const { loading: shiftsListLoading} = useShiftsListStore();

  const loading = shiftsListLoading;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    load({});
  }, [load]);

  return (
    <>
      <ShiftTableMenu />
      <PrimaryPaper sx={{ height: '100%', minHeight: 0, mt: 2 }}>
        <Flex column height="100%">
          <ShiftsFilters loading={loading}/>
          <Box mt={2} height="100%" minHeight={0}>
            <ShiftsTable />
          </Box>
        </Flex>
      </PrimaryPaper>
      <ShiftDetailsDrawer />
    </>
  );
}
