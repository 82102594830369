import { FormattedMessage } from 'react-intl';
import { useJobQualificationsData } from '@libs/store/talents';
import { useShiftsFilter } from '@libs/store/shifts';
import { FilterItem } from '@molecules/form/filterMenu';

export function useQualificationsFilterMenu(): FilterItem {
  const { data } = useJobQualificationsData();
  const { getFilter } = useShiftsFilter();
  const filter = getFilter<string[]>('qualifications');

  return {
    label: <FormattedMessage id="shifts.fields.qualifications" />,
    key: 'qualifications',
    multiple: true,
    valueToLabel: (value) => value.map((v) => data.find((l) => l.id === v)?.name).join(', '),
    children: data.map((item) => ({
      label: item.name,
      key: item.id,
      selected: filter?.value.includes(item.id) || false,
    })),
  };
}
