import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import { useShiftHistoryLog } from '@libs/store/shifts';
import { ShiftHistoryLog } from '@libs/models/shifts';
import { getShiftType } from '@libs/models/shifts/shift';
import { capitalize } from '@libs/utils/string';
import { DelayedLinearProgress } from '@molecules/feedback';
import { LogTimeline } from '@molecules/dataDisplay/timeline';
import { Box, Flex, PrimaryPaper } from '@atoms/layout';

export function ShiftHistory({ shiftId }: { shiftId: string }) {
  const { loadLog, loading, log } = useShiftHistoryLog();

  useEffect(() => {
    loadLog(shiftId);
  }, [loadLog, shiftId]);

  return (
    <>
      <DelayedLinearProgress show={loading} />
      <Fade in={!!log.length}>
        <Box>
          <PrimaryPaper>
            <Paper>
              <Flex>
                <LogTimeline
                  items={log.map((logItem) => ({
                    date: logItem.date,
                    content: <ShiftLogItem data={logItem} />,
                  }))}
                />
              </Flex>
            </Paper>
          </PrimaryPaper>
        </Box>
      </Fade>
    </>
  );
}

type ShiftLogItemProps = {
  data: ShiftHistoryLog;
};
function ShiftLogItem({ data }: ShiftLogItemProps) {
  const intl = useIntl();

  if (data.event === 'created') {
    const shiftStart = data.shiftDetails?.shiftStart;
    const shiftLength = data.shiftDetails?.shiftLength;
    const shiftEnd = shiftStart && shiftLength ? dayjs(shiftStart).add(shiftLength, 'millisecond').toDate() : undefined;
    return (
      <>
        <Typography variant="subtitle1" fontWeight={500}>
          <FormattedMessage id="shifts.logs.created" />
        </Typography>
        <Typography variant="body1">
          {shiftStart && shiftEnd && shiftLength && (
            <FormattedMessage
              id="shifts.logs.shiftTime"
              values={{
                date: intl.formatDate(shiftStart),
                timeRange: `${intl.formatTime(shiftStart, { hour: 'numeric' })} - ${intl.formatTime(shiftEnd, { hour: 'numeric' })}`,
                shiftLength: Math.round(shiftLength / 1000 / 60 / 60),
                shiftType: capitalize(getShiftType(dayjs(shiftStart))),
              }}
            />
          )}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage id={`common.byPerson`} values={{ name: data.user }} />
        </Typography>
      </>
    );
  }
  if (data.event === 'assigned' || data.event === 'started') {
    return (
      <>
        <Flex alignCenter gap={1}>
          <Typography variant="subtitle1" fontWeight={500}>
            <FormattedMessage id={`shifts.logs.${data.event}`} />
          </Typography>
          <Typography variant="body1">
            {data.shiftDetails?.assignee?.name} ({data.shiftDetails?.assignee?.specialty})
          </Typography>
        </Flex>
        <Typography variant="body2" color="text.secondary">
          <FormattedMessage id="common.byPerson" values={{ name: data.user }} />
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="subtitle1" fontWeight={500}>
        <FormattedMessage id={`shifts.logs.${data.event}`} />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <FormattedMessage id={`common.byPerson`} values={{ name: data.user }} />
      </Typography>
    </>
  );
}
