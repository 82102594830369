import { create } from 'zustand';
import { fetchRotationsList } from '@libs/api/shifts';
import { Rotation } from '@libs/models/shifts/shift';

type State = {
  loading: boolean;
  initiated: boolean;
  data: Rotation[];
  page: number;
  total: number;
  perPage: number;
  load: (page: number, perPage: number, org: string) => void;
  showDetails: boolean;
  currentShift: Rotation | null;
  openShiftDetails: (id: string) => void;
  closeShiftDetails: () => void;
};

export const useRotationsListStore = create<State>()((set, get) => ({
  loading: false,
  initiated: false,
  data: [],
  page: 1,
  perPage: 10,
  total: 0,
  load: async (page, perPage, org) => {
    set({ loading: true });
    try {
      const resp = await fetchRotationsList(page, perPage, org);
      set({
        loading: false,
        data: resp.data,
        page,
        perPage,
        total: resp.meta.total,
      });
    } finally {
      set({ loading: false, initiated: true });
    }
  },
  currentShift: null,
  showDetails: false,
  openShiftDetails: (id) => {
    const { data } = get();
    const shift = data.find((s) => s.id === id);
    set({ currentShift: shift, showDetails: true });
  },
  closeShiftDetails: () => {
    set({ showDetails: false });
  },
}));
