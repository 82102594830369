import { Shift } from '@libs/models/shifts';
import { ShiftOld } from '@libs/models/shifts/shift';
import { ShiftAssigneeButton } from '@organisms/shifts';

type AssigneeColumnProps = {
  shift: ShiftOld;
};
export function AssigneeColumn({ shift }: AssigneeColumnProps) {
  return <ShiftAssigneeButton shift={shift} />;
}
