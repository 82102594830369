import React, { LegacyRef, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebounce } from '@uidotdev/usehooks';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, TextFieldProps } from './textField';
import { Flex } from '../layout';

export type SearchFieldProps = Omit<TextFieldProps, 'value'> & {
  debounce?: number;
  onDebouncedChange?: (value: string) => void;
  value?: string;
};
export function SearchField({ onChange = noop, debounce = 300, value, onDebouncedChange, ...props }: SearchFieldProps) {
  const intl = useIntl();
  const theme = useTheme();
  const [internalValue, setInternalValue] = useState('');
  const debouncedValue = useDebounce(internalValue, debounce);
  const inputRef = React.useRef<HTMLDivElement>();

  useEffect(() => {
    const keydownListener = (keydownEvent: KeyboardEvent) => {
      const { repeat, ctrlKey, code } = keydownEvent;
      if (repeat) return;
      if (ctrlKey && code === 'KeyF') {
        keydownEvent.stopPropagation();
        keydownEvent.preventDefault();
        inputRef && inputRef?.current?.focus();
      }
    };
    window.addEventListener('keydown', keydownListener, true);
    return () => {
      window.removeEventListener('keydown', keydownListener, true);
    };
  }, [inputRef]);

  useEffect(() => {
    if (value) {
      setInternalValue(value ?? '');
    }
  }, [value]);

  useEffect(() => {
    onDebouncedChange?.(debouncedValue);
  }, [debouncedValue]);

  return (
    <TextField
      placeholder={intl.formatMessage({ id: 'common.search' })}
      ref={inputRef as LegacyRef<HTMLDivElement> | undefined}
      InputProps={{
        startAdornment: (
          <Flex sx={{ color: theme.palette.action.active }} mr={0.5}>
            <SearchIcon />
          </Flex>
        ),
      }}
      onChange={(e) => {
        setInternalValue(e.target.value);
        onChange(e);
      }}
      value={internalValue}
      {...props}
    />
  );
}

const noop = () => {};
