import { LocationExtra, LocationList, LocationShort } from '@libs/models/locations';
import { request } from '../../request';

export async function getOrgLocations(): Promise<{ data: LocationList[]; total: number }> {
  const response = await request.get<{ data: LocationList[]; total: number }>('/v2/settings/locations');

  return response.data;
  // const dataMock: LocationList[] = Object.values(mock);

  // const fake = async () =>
  //   new Promise<{ data: LocationList[]; total: number }>((resolve) => {
  //     setTimeout(() => {
  //       resolve({ data: dataMock, total: dataMock.length });
  //     }, 1000);
  //   });

  // return await fake();
}

export async function getLocationsSimple(): Promise<{ data: LocationShort[]; total: number }> {
  const response = await request.get<{ data: LocationShort[]; total: number }>('/v2/settings/locations');

  return response.data;
  // const dataMock: LocationList[] = Object.values(mock);

  // const fake = async () =>
  //   new Promise<{ data: LocationList[]; total: number }>((resolve) => {
  //     setTimeout(() => {
  //       resolve({ data: dataMock, total: dataMock.length });
  //     }, 1000);
  //   });

  // return await fake();
}

export async function getOrgLocation(locationId: string): Promise<LocationExtra> {
  const response = await request.get(`/v2/settings/locations/${locationId}`);
  const location = response.data;
  return {
    id: location.id,
    name: location.name,
    address: location.addressDetails,
    contact: {
      name: location.contact?.name || '',
      jobTitle: location.contact?.title || '',
      phonePrimary: location.contact?.phoneNumber || '',
      phoneSecondary: location.contact?.secondaryPhoneNumber || '',
      email: location.contact?.email || '',
    },
    isHeadquarters: location.isHQ,
    phoneNumber: location.phoneNumber,
    status: location.status,
    timezone: location.timeZone,
    workingWeekStart: location.workWeekDayStart,
    usedDepartmentNodes: location.usedDepartmentNodes,
    structure: location.structure,
  } as LocationExtra;
  // const fake = async () =>
  //   new Promise<LocationExtra>((resolve) => {
  //     setTimeout(() => {
  //       resolve(mock[locationId]);
  //     }, 1000);
  //   });

  // return await fake();
}

// const mock: Record<string, LocationExtra> = {
//   TestLocation1: {
//     id: 'TestLocation1',
//     name: 'HCA Healthcare',
//     isHeadquarters: true,
//     status: 'active',
//     address: {
//       secondAddress: 'One Park Plaza, Nashville, TN 37203',
//       firstAddress: 'One Park Plaza, Nashville, TN 37203',
//       region: 'TN',
//       zipCode: '1234',
//       city: 'Nashville',
//       state: 'TN',
//     },
//     region: {
//       city: 'Nashville',
//       state: 'TN',
//     },
//     contact: {
//       email: 'john.doe@actriv.com',
//       name: 'John Doe',
//       phonePrimary: '+123456789',
//       phoneSecondary: '+3806712345',
//       jobTitle: 'IT admin',
//     },
//     phoneNumber: '+123456789',
//     timezone: 'PST',
//     workingWeekStart: 'Monday',
//     zipCode: '123',
//   },
//   TestLocation2: {
//     id: 'TestLocation2',
//     name: 'West Hills Hospital',
//     isHeadquarters: false,
//     status: 'inactive',
//     address: {
//       secondAddress: '7300 Medical Center Dr, West Hills, CA 91307',
//       firstAddress: '7300 Medical Center Dr, West Hills, CA 91307',
//       city: 'West Hills',
//       state: 'CA',
//       region: 'CA',
//       zipCode: '1234',
//     },
//     region: {
//       city: 'West Hills',
//       state: 'CA',
//     },
//     contact: {
//       email: 'jane.doe@actriv.com',
//       name: 'Jane Doe',
//       phonePrimary: '+123456789',
//       phoneSecondary: '+3806712345',
//       jobTitle: 'Local admin',
//     },
//     phoneNumber: '+123456789',
//     timezone: 'PST',
//     workingWeekStart: 'Monday',
//     zipCode: '123',
//   },
//   TestLocation3: {
//     id: 'TestLocation3',
//     name: 'Medical City Dallas',
//     isHeadquarters: false,
//     status: 'inactive',
//     address: {
//       secondAddress: '7300 Medical Center Dr, West Hills, CA 91307',
//       firstAddress: '7777 Forest Ln, Dallas, TX 75230',
//       city: 'Dallas',
//       state: 'TX',
//       region: 'TX',
//       zipCode: '1234',
//     },
//     region: 'Dallas, TX',
//     contact: {
//       email: 'andrew.doe@actriv.com',
//       name: 'Andrew Doe',
//       phonePrimary: '+123456789',
//       phoneSecondary: '+3806712345',
//       jobTitle: 'Local admin',
//     },
//     phoneNumber: '+123456789',
//     timezone: 'PST',
//     workingWeekStart: 'Monday',
//     zipCode: '123',
//   },
// };

export async function createOrgLocation(locationInfo: LocationExtra): Promise<void> {
  const response = await request.post('/v2/settings/locations', {
    name: locationInfo.name,
    addressDetails: locationInfo.address,
    phoneNumber: locationInfo.phoneNumber,
    contact: {
      email: locationInfo.contact.email,
      phoneNumber: locationInfo.contact.phonePrimary,
      secondaryPhoneNumber: locationInfo.contact.phoneSecondary,
      name: locationInfo.contact.name,
      title: locationInfo.contact.jobTitle,
    },
    status: locationInfo.status,
    timeZone: locationInfo.timezone,
    workWeekDayStart: locationInfo.workingWeekStart,
    isHQ: locationInfo.isHeadquarters,
    usedDepartmentNodes: locationInfo.usedDepartmentNodes,
  });

  // const dataMock: LocationList[] = Object.values(mock);

  // const fake = async () =>
  //   new Promise<{ data: LocationList[]; total: number }>((resolve) => {
  //     setTimeout(() => {
  //       resolve({ data: dataMock, total: dataMock.length });
  //     }, 1000);
  //   });

  // return await fake();
}

export async function updateOrgLocation(id: string, locationInfo: LocationExtra): Promise<void> {
  const response = await request.patch(`/v2/settings/locations/${id}`, {
    name: locationInfo.name,
    addressDetails: locationInfo.address,
    phoneNumber: locationInfo.phoneNumber,
    contact: {
      email: locationInfo.contact.email,
      phoneNumber: locationInfo.contact.phonePrimary,
      secondaryPhoneNumber: locationInfo.contact.phoneSecondary,
      name: locationInfo.contact.name,
      title: locationInfo.contact.jobTitle,
    },
    status: locationInfo.status,
    timeZone: locationInfo.timezone,
    workWeekDayStart: locationInfo.workingWeekStart,
    isHQ: locationInfo.isHeadquarters,
    usedDepartmentNodes: locationInfo.usedDepartmentNodes,
  });

  // const dataMock: LocationList[] = Object.values(mock);

  // const fake = async () =>
  //   new Promise<{ data: LocationList[]; total: number }>((resolve) => {
  //     setTimeout(() => {
  //       resolve({ data: dataMock, total: dataMock.length });
  //     }, 1000);
  //   });

  // return await fake();
}
