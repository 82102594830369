import { Box, Flex, PrimaryPaper } from '@atoms/layout';
import { GlobalStyles, Stack, Typography, Chip, alpha, Avatar } from '@mui/material';
import { Doctor } from '../scheduler/lib/Doctor';
import { FaIcon } from '@atoms/icons';
import { useMemo } from 'react';

type CalendarItemProps = {
  data: Doctor;
  hoursScheduled: number;
};

export function ResourceItem({ data, hoursScheduled }: CalendarItemProps) {
  return (
    <Stack direction={'column'}>
      <Stack
        direction={'row'}
        spacing={1}
        sx={(theme) => ({
          width: '100%',
          height: '64px',
          paddingLeft: '8px',
          paddingTop: '8px',
          // borderBottom: `1px solid ${theme.palette.divider}`,
        })}
      >
        <Avatar src={data.avatar} sx={{ top: '8px', width: '32px', height: '32px' }} />
        <Stack direction={'column'} flex={1} sx={(theme) => ({})}>
          <Stack
            direction={'row'}
            sx={(theme) => ({
              paddingBottom: '4px',
            })}
          >
            <Typography
              sx={(theme) => ({
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: 'bolder',
                letterSpacing: '0.1px',
                color: theme.palette.text.primary,
              })}
            >
              {data.firstName || ''} {data.lastName || ''}
            </Typography>
            {/* Rating - non used for now */}
            {/* <Stack direction={'row'} sx={{ marginTop: '1px', marginLeft: '4px' }}>
              <FaIcon name="star" variant="solid" color={'#ffb400'} width={16} height={16} />
              <Typography
                sx={(theme) => ({
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                })}
              >
                {data.rating}
              </Typography>
            </Stack> */}
            <Box flex={1} />
          </Stack>

          <Stack direction={'row'} sx={(theme) => ({})}>
            <Chip label={data.nurseQualification.abbreviation} size="small" sx={{ fontWeight: 500 }} />
            <Box flex={1} />
            <Stack direction={'row'} sx={{ marginTop: '4px', marginLeft: '4px', marginRight: '4px' }} spacing={0.5}>
              <FaIcon name="clock" variant="solid" width={16} height={16} />
              <Typography
                sx={(theme) => ({
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  color: hoursScheduled >= 40 ? theme.palette.error.main : undefined,
                })}
              >
                {hoursScheduled} / {40}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
