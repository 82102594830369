import { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@atoms/buttons';
import { Flex } from '@atoms/layout';
import { MultiSelect, MultiSelectProps } from './multiSelect';
import { renderCheckboxOption } from './selectOptions/renderCheckboxOption';

export type FilterMultiSelectProps = Omit<MultiSelectProps, 'ref'> & {
  inlineLabel?: boolean;
  onApply?: (value: string[]) => void;
  onClear?: () => void;
};
export function FilterMultiSelect({
  options,
  inlineLabel,
  label,
  onChange,
  onApply,
  onClear,
  value,
  ...props
}: FilterMultiSelectProps) {
  const [internalValue, setInternalValue] = useState<string[]>(value);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <MultiSelect
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
      size="small"
      renderValue={(selected) => {
        const value = selected
          .map((v) => options.find((o) => o.value === v)?.label)
          .filter(Boolean)
          .join(', ');
        if (inlineLabel) {
          return <InlineLabel label={label} value={value.length === 0 ? 'All' : value} />;
        }
        return value;
      }}
      options={options}
      label={inlineLabel ? undefined : label}
      onChange={(v, e) => {
        setInternalValue(v);
        onChange?.(v, e);
      }}
      value={internalValue}
      controls={
        <Flex justifyContent="flex-end" width="100%" minWidth="340px" gap={2}>
          <Box color="text.secondary">
            <Button
              rounded
              variant="text"
              color="inherit"
              onClick={() => {
                setOpen(false);
                onClear?.();
              }}
            >
              <FormattedMessage id="common.clear" />
            </Button>
          </Box>
          <Button
            rounded
            variant="contained"
            onClick={() => {
              setOpen(false);
              onApply?.(internalValue);
            }}
          >
            <FormattedMessage id="common.applyFilters" />
          </Button>
        </Flex>
      }
      renderOption={renderCheckboxOption}
      {...props}
    />
  );
}

type InlineLabelProps = {
  label: ReactNode;
  value: string;
};
function InlineLabel({ label, value }: InlineLabelProps) {
  return (
    <Flex>
      <Typography variant="body1" color="text.secondary" sx={{ flexShrink: 0 }}>
        {label}&nbsp;
      </Typography>
      <Typography variant="body1" color="text.primary" noWrap title={value}>
        {value}
      </Typography>
    </Flex>
  );
}
