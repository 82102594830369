import { FormattedMessage } from 'react-intl';
import { ToggleButton, ToggleButtonGroup } from '@atoms/buttons';
// import { DuotoneIcon, FaIcon } from '@atoms/icons';
import { Flex } from '@atoms/layout';
import { useNavigate } from 'react-router-dom';

export function ShiftTableMenu() {
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const paths = {
    series: "/schedule/list/series-n-rotations",
    shifts: "/schedule/list/all-shifts",
  };
  const locationListMapping: Record<string, string> ={
    [paths.series]:"series",
    [paths.shifts]:"shifts"
  };

  return (
    <>
      <Flex gap={2}>
        <ToggleButtonGroup aria-label="shift type switcher" value={locationListMapping[currentPath]}>
          <ToggleButton value="shifts" size="large" onClick={()=>navigate(paths.shifts)}>
            <FormattedMessage id="shifts.type.shifts" />
          </ToggleButton>
          <ToggleButton value="series" size="large" onClick={()=>navigate(paths.series)}>
            <FormattedMessage id="shifts.type.series" />
          </ToggleButton>
        </ToggleButtonGroup>
        {/* <ToggleButtonGroup value="list" aria-label="view type switcher">
          <ToggleButton value="list" aria-label="list" size="large">
            <Flex mx={0.5}>
              <DuotoneIcon name="list" active />
            </Flex>
          </ToggleButton>
          <ToggleButton value="calendar" aria-label="calendar" size="large">
            <Flex mx={0.5}>
              <FaIcon name="calendar" />
            </Flex>
          </ToggleButton>
        </ToggleButtonGroup> */}
      </Flex>
    </>
  );
}
